import {Query} from "@datorama/akita";
import {AuthState} from "./models";
import {AuthStore} from "./store";

export class AuthQuery extends Query<AuthState> {
    checked$ = this.select(state => state.checked)
    user$ = this.select(state => state.user);
    authenticated$ = this.select(state => state.authenticated);

    constructor(private authStore: AuthStore) {
        super(authStore);
    }

    get isAuthenticated() {
        return this.getValue().authenticated
    }
}