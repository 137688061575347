import {PlayerStore} from "./store";
import {PlayerQuery} from "./query";
import {LeagueQuery} from "../league/query";
import {League} from "../league/models";
import {filterNullish} from "../../util/operators";
import {applyTransaction, ID} from "@datorama/akita";
import {ajax} from "rxjs/ajax";
import {
    isPlayer,
    ListPlayerResult,
    listPlayerResultToPlayer,
    Player,
    PlayerRequest,
    playerToUpdatePlayerRequestJson
} from "./models";
import {PlayerGateway, PlayerRoutes} from "./gateway";
import {map, tap} from "rxjs/operators";
import {Player as ApiPlayer, ResponseWrapper, ShowPlayerResponse} from "../../api/types";

export class PlayerService {
    constructor(private store: PlayerStore, private query: PlayerQuery, private gateway: PlayerGateway, private leagueQuery: LeagueQuery) {
        leagueQuery.selectActive<League | undefined>().pipe(filterNullish()).subscribe(l => this.onSelectLeague(l));
        this.gateway.list$.subscribe(r => this.onList(r))
        this.gateway.show$.subscribe(r => this.onShow(r))
    }

    list(leagueId: ID) {
        this.gateway.onList$.next(leagueId)
    }

    select(id: ID) {
        this.store.setActive(id);
        this.gateway.onShow$.next(id)
    }

    // Old
    loadAll(leagueId: ID) {
        ajax.getJSON<ListPlayerResult[]>(PlayerRoutes.list(leagueId))
            .pipe(tap(() => this.store.setLoading(true)))
            .subscribe(results => {
                const entities = results.map(listPlayerResultToPlayer) as unknown as Player[]
                this.store.add(entities)
                this.store.setLoading(false);
                this.store.isReady();
            });
    }

    create(playerRequest: PlayerRequest, done: () => void) {
        ajax.post(PlayerRoutes.create(), playerRequest, {'Content-Type': 'application/json'})
            .pipe(
                tap(() => this.store.ui.setLoading(true)),
                map(response => response.response)
            )
            .subscribe({
                next: response => {
                    this.store.add(listPlayerResultToPlayer(response))
                    this.store.ui.setLoading(false);
                    done();
                },
                error: err => console.error(`Could not complete request ${PlayerRoutes.create()} with request`, playerRequest, err)
            })
    }

    setActive(id: ID) {
        const active = this.query.getActive();
        if (active && active.id === id) {
            this.store.setActive(null);
        } else {
            this.store.setActive(id);
        }
    }

    cancelActive() {
        this.store.setActive(null);
    }

    delete(active: Player | undefined) {
        if (active) {
            ajax.delete(PlayerRoutes.destroy(active.id))
                .pipe(tap(() => this.store.setLoading(true)))
                .subscribe(response => {
                    this.store.remove(active.id);
                    this.store.setLoading(false);
                });
        }

    }

    resetRating(active: Player | undefined) {
        if (active) {
            const body = playerToUpdatePlayerRequestJson(active);

            ajax.put(PlayerRoutes.updateRating(active.id), body, {'Content-Type': 'application/json'})
                .pipe(tap(() => this.store.ui.update(active.id, {isLoading: true})))
                .subscribe({
                    next: response => {
                        if (response) {
                            const player = isPlayer(response.response) && response.response as Player;
                            if (player) {
                                this.store.update(player.id, {rating: player.rating})
                            }
                        }
                    },
                    error: err => console.error('error', err)
                })
        }
    }

    update(updatedPlayer: Player) {
        ajax.put(PlayerRoutes.update(updatedPlayer.id), updatedPlayer, {'Content-Type': 'application/json'})
            .pipe(
                tap(() => this.store.ui.update(updatedPlayer.id, {isLoading: true})),
                map(response => response.response)
            )
            .subscribe(response => {
                this.store.update(response.id, response);
                this.store.ui.update(response.id, {isEditing: false, isLoading: false})
            })
    }

    editPlayer(player: Player) {
        this.store.ui.update(player.id, {isEditing: true});
    }

    cancelEditPlayer(player: Player) {
        this.store.ui.update(player.id, {isEditing: false});
    }

    setIsInMatchBuilder(player: Player, isInMatchBuilder: boolean) {
        this.store.ui.update(player.id, {isInMatchBuilder});
    }

    setRating(playerId: ID, updatedRating: number) {
        // @ts-ignore
        this.store.update(playerId, {rating: updatedRating})
    }

    setTempRating(tempRating: number) {
        let activeId = this.query.getActiveId();
        if (activeId) {
            this.store.ui.update(activeId, {tempRating});
        } else {
            console.error('no active player')
        }
    }

    private onSelectLeague(activeLeague: League) {
        applyTransaction(() => {
            this.store.reset()
            this.list(activeLeague.id)
        })
    }

    private onList(response: ResponseWrapper<{ players: ApiPlayer[] }>) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.reset()
            this.store.add(response.data.players)
        })
    }

    private onShow(response: ShowPlayerResponse) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.add(response.data)
        })
    }
}