import React, {Dispatch, useEffect, useMemo, useReducer} from 'react';
import {AppContainer} from "../domain/AppContainer";
import './LeagueEvents.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Action, initialState, reducer} from "../domain/leagueevent/reducer";
import {LeagueEvent, LeagueEventsState} from "../domain/leagueevent/models";
import {CreateLeagueEvent} from "../components/CreateLeagueEvent";
import {GroupSetup} from "../components/GroupSetup";
import {RecordResults} from "../components/RecordResults";
import {ReviewEvent} from "../components/ReviewEvent";
import {useActiveEntity, useEntityStore} from "../hooks/useEntityStore";
import {eventToLi, LeagueEventsList, LeagueEventsListItem} from "../component-library/LeagueEventsList";
import {Snackbar} from "../component-library/Snackbar";

interface Props {
    container: AppContainer
}

export function LeagueEvents({container}: Props) {
    // this needs to move down a level so initialState can be passed to it
    const [, dispatch] = useReducer(reducer, initialState)
    const active = useActiveEntity(container.leagueEvent.query.active$)
    const events = useEntityStore<LeagueEventsState>(container.leagueEvent.query.events$)
    useEffect(() => {
        container.leagueEvent.service.getAllForLeague()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!active && events && events.length > 0) {
            container.leagueEvent.service.selectLeagueEvent(events[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, events])

    const activeEvents: LeagueEventsListItem[] = useMemo(() => events.filter(e => e.status !== "done").map(eventToLi), [events]);
    const previousEvents: LeagueEventsListItem[] = useMemo(() => events.filter(e => e.status === "done").map(eventToLi), [events]);

    return <div className="league-events">
        <section className="league-events-left">
            <button
                className="button"
                onClick={() => {
                    container.leagueEvent.service.clear()
                    container.leagueEvent.service.createLeagueEvent()
                }}>
                <FontAwesomeIcon icon={faPlus}/> New Event
            </button>
            <LeagueEventsList
                title={"Active Events"}
                leagueEvents={activeEvents}
                onClick={id => container.leagueEvent.service.selectLeagueById(id)}/>

            <LeagueEventsList
                title={"Past Events"}
                leagueEvents={previousEvents}
                onClick={id => container.leagueEvent.service.selectLeagueById(id)}/>
        </section>
        <section className="league-events-main">
            {events && events.length === 0 && (
                <Snackbar level={"info"} showIcon={true}>
                    Creating an event allows you to quickly record the results of a round robin tournament.
                    <p>Click <button className="link-button"
                                     onClick={() => container.leagueEvent.service.createLeagueEvent()}>New
                        Event</button> to get started.</p>

                </Snackbar>
            )}
            {active && <LeagueEventBody
                container={container}
                event={active}
                dispatch={dispatch}/>}
        </section>
    </div>
}

interface LeagueEventBodyProps {
    container: AppContainer
    event: LeagueEvent
    dispatch: Dispatch<Action<any>>
}

function LeagueEventBody({container, event}: LeagueEventBodyProps) {
    if (!event) {
        return null;
    }

    switch (event.status) {
        case "not-started":
            return <CreateLeagueEvent container={container} event={event}/>
        case "setting-up":
        case "in-progress":
            return <GroupSetup container={container} event={event}/>
        case "recording":
            return <RecordResults container={container} event={event}/>
        case "done":
            return <ReviewEvent container={container} event={event}/>
        default:
            throw new Error('state not implemented')
    }
}