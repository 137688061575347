import React, {PropsWithChildren, ReactNode, useState} from 'react';
import './PageWrapper.css';
import {AppContainer} from "../domain/AppContainer";
import {SignInButton} from "../components/SignInButton";
import {UserControlPanel} from "../components/UserControlPanel";
import {SlidingPanel} from "./SlidingPanel";
import {useObservable} from "../hooks/useEntityStore";
import {AppTitle} from "./AppTitle";
import {Footer} from "./Footer";
import {Modal} from "../components/Modal";

interface Props {
    children: ReactNode
    container: AppContainer
    className?: string
}

export function PageWrapper(props: Props) {
    const [panelOpen, togglePanelOpen] = useState<boolean>(false)
    const user = useObservable(props.container.auth.query.user$)
    const showModal = useObservable(props.container.ui.query.modal$)

    return <>
        <div className={`App page-wrapper ${props.className}`}>
            <header className="page-header">
                <div className="header-row">
                    <section className="page-header-item page-header-item-left">
                        <AppTitle/>
                    </section>
                    <section className="page-header-item page-header-item-right">
                        <SignInButton
                            onClickUser={() => togglePanelOpen(true)}
                            container={props.container}/>
                    </section>
                </div>
            </header>

            <main className="page-body">
                {props.children}
            </main>

            <Footer/>
        </div>

        {showModal && <Modal container={props.container}/>}

        <SlidingPanel open={panelOpen}>
            <UserControlPanel
                user={user}
                onClose={() => togglePanelOpen(false)}/>
        </SlidingPanel>
    </>
}

export function AnonymousPageWrapper(props: PropsWithChildren<{}>) {
    return <div className={`anonymous-page-wrapper`}>
        <header className="anonymous-page-wrapper-header">
            <AppTitle/>
        </header>

        <main className={`page-body`}>
            {props.children}
        </main>

        <Footer/>
    </div>
}