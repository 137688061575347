import Fuse from "fuse.js";
import {Dispatch, useEffect, useState} from "react";

export function useFuseFilter<T>(entities: T[], optionsOverride: Partial<Fuse.IFuseOptions<any>>): [T[], string, Dispatch<any>] {
    const [criteria, setCriteria] = useState<string>('');
    const [filteredEntities, setFilteredEntities] = useState<T[]>([]);

    let options = {shouldSort: true, threshold: 0.2};
    Object.assign(options, optionsOverride)

    useEffect(() => {
        if (criteria === '') {
            setFilteredEntities(entities)
        } else {
            const fuse = new Fuse(entities, options)
            const results = fuse.search(criteria)
            setFilteredEntities(results.map(r => r.item));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entities, criteria])

    return [filteredEntities, criteria, setCriteria]
}