import {LeagueStore} from "./store";
import {ajax} from "rxjs/ajax";
import {isLeague, League, LeagueRequest} from "./models";
import {applyTransaction, ID} from "@datorama/akita";
import {LeagueGateway} from "./gateway";
import {
    CreateLeagueRequest,
    League as ServerLeague,
    ListLeaguesResponse,
    Permission,
    PermissionAbility,
    ShowLeagueResponse,
    UpdateLeaguePermissionsRequest
} from "../../api/types";
import {tap} from "rxjs/operators";
import {PartialObserver} from "rxjs";
import {LeagueQuery} from "./query";
import {AuthService} from "../auth/service";
import {Routes} from "../../api/endpoints";

function listResponseToLeagues(leagues: ServerLeague[]): League[] {
    return leagues.map(showResponseToLeague)
}

function showResponseToLeague(league: ServerLeague): League {
    return {
        ...league,
        id: league.id,
        name: league.name,
        isPublic: league.public,
        permissions: [],
        players: []
    }
}

export class LeagueService {
    private readonly list$: PartialObserver<ListLeaguesResponse> = {
        next: response => {
            const leagues = listResponseToLeagues(response.data.leagues)
            applyTransaction(() => {
                this.store.add(leagues)
                this.store.setLoading(false);
            })
        },
        error: err => {
            console.error('list - error', err);
            this.store.setLoading(false);
        }
    }
    private readonly show$: PartialObserver<ShowLeagueResponse> = {
        next: response => {
            const league = showResponseToLeague(response.data)
            applyTransaction(() => {
                this.store.add(league);
            })
        },
        error: err => {
            console.error('show - error', err);
        }
    }
    private readonly create$: PartialObserver<ShowLeagueResponse> = {
        next: response => {
            const league = showResponseToLeague(response.data)
            applyTransaction(() => {
                this.store.add(league)
                this.store.setLoading(false)
            })

            this.authService.get()
        },
        error: err => {
            console.error('create - error', err);
        }
    }
    private readonly update$: PartialObserver<ShowLeagueResponse> = {
        next: response => {
            console.warn('TODO: update - next', response)
        },
        error: err => {
            console.error('update - error', err);
        }
    }
    // TODO: Look up this return type
    private readonly destroy$: PartialObserver<any> = {
        next: response => {
            console.warn('TODO: destroy - next', response)
        },
        error: err => {
            console.error('destroy - error', err);
        }
    }

    constructor(private store: LeagueStore, private gateway: LeagueGateway, private query: LeagueQuery, private authService: AuthService) {
        this.gateway.list$.subscribe(this.list$)
        this.gateway.show$.subscribe(this.show$)
        this.gateway.create$.subscribe(this.create$)
        this.gateway.update$.subscribe(this.update$)
        this.gateway.destroy$.subscribe(this.destroy$)
        this.gateway.listPermissions$.subscribe({
            next: v => {
                this.store.updateActive({permissions: v.data.permissions})
            },
            error: err => {
                console.error('permission err', err)
            }
        })

        this.gateway.updatePermission$.subscribe({
            next: v => {
                this.store.updateActive({permissions: v.data.permissions})
            }, error: err => console.error('update - err', err)
        })
    }

    list() {
        this.gateway.onList$.next(null)
    }

    get2(id: ID) {
        const ids = this.query.getAll().map(v => v.id)
        if (ids.indexOf(id) === -1) {
            this.gateway.onShow$.next(id)
        }
    }

    select(id: ID) {
        this.store.setActive(id)
        this.gateway.onShow$.next(id)
    }

    listPermissions() {
        const activeId = this.query.getActiveId()
        if (activeId != null) {
            this.gateway.onListPermissions$.next(activeId)
        }
    }

    create2(request: CreateLeagueRequest) {
        this.gateway.onCreate$.next(request)
    }

    updateName(name: string) {
        this.store.updateActive((prevState) => {
            let request = {name, public: prevState.isPublic};
            this.gateway.onUpdate$.next([prevState.id, request])
            return {name}
        })
    }

    updateAddress(address: string) {
        this.store.updateActive((prevState) => {
            let request = {...prevState, address, public: prevState.isPublic};
            this.gateway.onUpdate$.next([prevState.id, request])
            return {address}
        })
    }

    updateWebsite(website: string) {
        this.store.updateActive((prevState) => {
            let request = {...prevState, website, public: prevState.isPublic};
            this.gateway.onUpdate$.next([prevState.id, request])
            return {website}
        })
    }

    updateImageUrl(imageUrl: string) {
        this.store.updateActive((prevState) => {
            let request = {...prevState, imageUrl, public: prevState.isPublic};
            this.gateway.onUpdate$.next([prevState.id, request])
            return {imageUrl}
        })
    }

    toggleVisibility() {
        this.store.updateActive(prevState => {
            let request = {name: prevState.name, public: !prevState.isPublic};
            this.gateway.onUpdate$.next([prevState.id, request])
            return {isPublic: !prevState.isPublic}
        })
    }

    destroy(id: ID) {
        this.gateway.onDestroy$.next(id)
    }

    addRole(id: number) {
        const activeId = this.query.getActiveId()
        if (activeId) {
            const request: UpdateLeaguePermissionsRequest = {
                leagueId: activeId,
                userId: id,
                resource: 'all',
                ability: "none"
            }
            this.gateway.onUpdatePermission$.next([activeId, request])
        }
    }

    updatePermission(p: Permission, ability: PermissionAbility) {
        const request: UpdateLeaguePermissionsRequest = {
            ...p,
            ability,
        }
        this.gateway.onUpdatePermission$.next([p.leagueId, request])
    }

    //TODO: refactor to use v2 api
    //TODO: refactor into its own package
    loadAll() {
        ajax.getJSON<League[]>(LeagueRoutes.list())
            .pipe(
                tap(() => this.store.setLoading(true)),
            )
            .subscribe({
                next: entities => {
                    this.store.add(entities)
                    this.store.setLoading(false);
                },
                error: (e) => {
                    this.store.setLoading(false);
                },
                complete: () => {
                    this.store.setLoading(false);
                }
            });
    }

    get(id: ID) {
        ajax.getJSON<League>(LeagueRoutes.get(id)).subscribe(entity => {
            this.store.add(entity);
        });
    }

    setActive(id: ID) {
        this.store.setActive(id);
    }

    updateActive(newLeague: League) {
        this.store.updateActive(newLeague)
    }

    create(leagueRequest: LeagueRequest, doneCb: () => void) {
        ajax.post(LeagueRoutes.create(), leagueRequest, {'Content-Type': 'application/json'})
            .subscribe({
                next: response => isLeague(response.response) && this.store.add(response.response),
                error: err => console.error(err),
                complete: doneCb
            })
    }
}

const LeagueRoutes: Routes = {
    list: () => `/api/leagues`,
    get: (id: ID) => `/api/leagues/${id}`,
    create: () => `/api/leagues`,
    update: (id: ID) => `/api/leagues/${id}`,
    destroy: (id: ID) => `/api/leagues/${id}`
}