import {League} from "../domain/league/models";
import {AppContainer} from "../domain/AppContainer";
import {WorkPane, WorkPaneHeader} from "../component-library/WorkPane";
import {FieldsetInput} from "../component-library/Fieldsets";
import React from "react";

interface LeagueInfoSettingsProps {
    active?: League
    container: AppContainer
}

export function LeagueInfoSettings({active, container}: LeagueInfoSettingsProps) {
    return <WorkPane>
        <WorkPaneHeader>League Information</WorkPaneHeader>
        <FieldsetInput
            label={"Name"}
            id={"name"}
            value={active?.name || ""}
            onChange={(val) => {
                container.league.service.updateName(val)
            }}/>

        <FieldsetInput
            label={"Address"}
            id={"address"}
            value={active?.address || ""}
            onChange={(val) => {
                container.league.service.updateAddress(val)
            }}/>

        <FieldsetInput
            label={"Website"}
            id={"website"}
            value={active?.website || ""}
            onChange={(val) => {
                container.league.service.updateWebsite(val)
            }}/>

        <FieldsetInput
            label={"Image URL"}
            id={"image-url"}
            value={active?.imageUrl || ""}
            onChange={(val) => {
                container.league.service.updateImageUrl(val)
            }}/>
    </WorkPane>
}