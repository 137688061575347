import {UIStore} from "./store";
import {UIService} from "./service";
import {UIQuery} from "./query";

export class UIContainer {
    readonly service: UIService;
    readonly query: UIQuery;
    private readonly store: UIStore;

    constructor() {
        this.store = new UIStore()
        this.service = new UIService(this.store);
        this.query = new UIQuery(this.store);
    }
}