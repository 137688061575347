import {QueryEntity} from "@datorama/akita";
import {MatchState, RatingChange} from "./models";
import {combineLatest, Observable} from "rxjs";
import {MatchStore} from "./store";
import {map} from "rxjs/operators";
import {takePlayerRating} from "./utils";
import {PlayerQuery} from "../player/query";
import {Player} from "../player/models";

export class MatchQuery extends QueryEntity<MatchState> {
    readonly matchBuilderPlayers$ = this.select(state => state.ui.matchBuilderPlayers);
    readonly ratingChanges$: Observable<RatingChange[]>;

    constructor(protected store: MatchStore, private playerQuery: PlayerQuery) {
        super(store);

        this.ratingChanges$ = combineLatest([playerQuery.selectActiveId(), this.selectAll()])
            .pipe(map(([id, matches]) => {
                if (id && matches) {
                    return matches.map(m => takePlayerRating(id, m))
                }

                return []
            }));
    }

    get matchBuilderPlayers(): Player[] {
        return this.getValue().ui.matchBuilderPlayers
    }
}