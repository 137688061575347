import {ActiveState, EntityState, ID} from "@datorama/akita";
import {Match} from "../match/models";
import {dateToDatestring, offsetDate} from "./utils";
import {Player} from "../player/models";

export interface LeagueEvent {
    id: ID
    leagueId: ID
    name: string
    dateString: string
    // Remove?
    date: Date
    status: LeagueEventProgress
    participants: GroupPlayer[]
    tables: number
    groupCount: number
    matches: NewLeagueEventMatch[]
}

export interface GroupPlayer {
    playerId: ID
    groupNumber: number
    player: Player
}

export function groupNumber(n: number): Partial<GroupPlayer> {
    return {
        groupNumber: n
    }
}

export type LeagueEventProgress = 'not-started' | 'setting-up' | 'in-progress' | 'recording' | 'done';

export function defaultLeagueEvent(): LeagueEvent {
    const dateString = dateToDatestring(offsetDate(new Date()))

    return {
        id: 0,
        leagueId: 0,
        dateString,
        name: '',
        status: "not-started",
        date: new Date(),
        participants: [],
        tables: 0,
        groupCount: 0,
        matches: [],
    }
}

export interface LeagueEventsState extends EntityState<LeagueEvent, number>, ActiveState {
}

export interface LeagueEventMatch {
    groupNumber: number
    match: Match
}

export interface NewLeagueEventMatch {
    id: ID,
    leagueEventId: ID,
    players: Player[],
    status: string,
    groupNumber: number
    winnerId: ID
    match?: Match
}

//Deprecated
export type LeaguePlayer = { id: number, name: string, group: number, rating: number }

//Deprecated
export type NewGroups = { [group: string]: { [id: string]: LeaguePlayer } }

//Deprecated
export type Groups = { [group: string]: { [id: string]: Player } }

export interface GroupInfo {
    min: number
    max: number
}

export type GroupInfoById = { [groupNumber: number]: GroupInfo }

export type GroupPlayersByGroup = { [groupNumber: number]: GroupPlayer[] }

export type MatchesByPlayerId = { [playerIds: string]: LeagueEventMatch[] }
export type MatchesByLeagueEventMatchId = { [id: string]: NewLeagueEventMatch[] }
