import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import './LeaguePlayersPrintView.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from "@fortawesome/free-regular-svg-icons/faSquare";
import {useObservable, useObservableWithInitialValue} from "../hooks/useEntityStore";
import {useParams} from "react-router-dom";
import {Player} from "../domain/player/models";
import QRCode from "react-qr-code";

interface Props {
    container: AppContainer
}

interface Params {
    leagueId: string
}

export function LeaguePlayersPrintView({container}: Props) {
    let {leagueId} = useParams<Params>();
    const players = useObservableWithInitialValue(container.player.query.selectAll(), [])
    const ready = useObservable(container.player.query.ready$)
    const [firstHalf, setFirstHalf] = useState<Player[]>([])
    const [secondHalf, setSecondHalf] = useState<Player[]>([])

    useEffect(() => {
        container.initLeague(leagueId)
    }, [leagueId, container])

    useEffect(() => {
        if (ready) {
            window.print();
        }
    }, [ready])

    useEffect(() => {
        const half = Math.ceil(players.length / 2)
        setFirstHalf(players.slice(0, half));
        setSecondHalf(players.slice(-half));
    }, [players])

    return <>
        <header className="print-view-header">
            <div>See it online</div>
            <QRCode value={window.location.href.replace('/print', '')} level={"H"} size={64}/>
            <div>{window.location.href.replace('/print', '')}</div>
        </header>
        <div className="league-players-print-view">

            <table>
                <thead>
                <tr>
                    <th className="empty-header"/>
                    <th className="align-left">First Name</th>
                    <th className="align-left">Last Name</th>
                    <th className="align-right">Rating</th>
                </tr>
                </thead>
                <tbody>
                {firstHalf.map((p, i) =>
                    <tr key={i}>
                        <td className="align-right checkbox"><FontAwesomeIcon icon={faSquare}/></td>
                        <td className="align-left">{p.firstName}</td>
                        <td className="align-left">{p.lastName}</td>
                        <td className="align-right">{p.rating}</td>
                    </tr>
                )}
                </tbody>
            </table>
            <table>
                <thead>
                <tr>
                    <th className="empty-header"/>
                    <th className="align-left">First Name</th>
                    <th className="align-left">Last Name</th>
                    <th className="align-right">Rating</th>
                </tr>
                </thead>
                <tbody>
                {secondHalf.map((p, i) =>
                    <tr key={i}>
                        <td className="align-right checkbox"><FontAwesomeIcon icon={faSquare}/></td>
                        <td className="align-left">{p.firstName}</td>
                        <td className="align-left">{p.lastName}</td>
                        <td className="align-right">{p.rating}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </>
}