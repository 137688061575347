import React from "react";

interface Props {
    iframe: string
}

export function Iframe(props: Props) {
    return <div className="iframe-wrapper">
        <div dangerouslySetInnerHTML={toIframe(props.iframe)}/>
    </div>
}

function toIframe(html: string) {
    return {
        __html: html
    }
}