import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import './PlayerPane.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {Subscription} from "rxjs";
import {filterNullish} from "../util/operators";
import {formatDate} from "../util/dates";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons/faArrowDown";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {RESET_RATING_SLUG} from "./Modal";
import {Match, RatingChange} from "../domain/match/models";
import {isPlayerUI, Player, PlayerUI} from "../domain/player/models";
import {ratingChangesToChartData, RatingChartData, toMatchHistoryRow} from "../domain/match/utils";

interface Props {
    container: AppContainer
}

export function PlayerPane({container}: Props) {
    const [active, setActive] = useState<Player | undefined>();
    const [draftFirstName, setDraftFirstName] = useState<string>('')
    const [draftLastName, setDraftLastName] = useState<string>('')
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [activeUI, setActiveUI] = useState<PlayerUI | undefined | unknown>();
    const [matches, setMatches] = useState<Match[]>([]);
    const [matchesLoading, setMatchesLoading] = useState<boolean>(false);
    const [ratingChanges, setRatingChanges] = useState<RatingChange[]>([]);
    const [ratingChartData, setRatingChartData] = useState<RatingChartData[]>([]);

    useEffect(() => {
        const subs: Subscription[] = []
        subs.push(container.player.query.selectActive().subscribe(setActive));
        subs.push(container.player.query.activeUI$.subscribe(setActiveUI));
        subs.push(container.match.query.selectAll().pipe(filterNullish()).subscribe((v: Match[]) => {
            if (v) {
                setMatches(v)
            }
        }));
        subs.push(container.match.query.selectLoading().subscribe(setMatchesLoading))
        subs.push(container.match.query.ratingChanges$.subscribe(setRatingChanges))
        return () => {
            subs.forEach(s => s.unsubscribe());
        }
    }, [container.player.query, container.match.query])

    useEffect(() => {
        const chartData = ratingChangesToChartData(ratingChanges)
        setRatingChartData(chartData);
    }, [ratingChanges])

    const onClickEdit = () => {
        if (active) {
            setDraftFirstName(active.firstName)
            setDraftLastName(active.lastName)
            container.player.service.editPlayer(active);
        }
    }

    // TODO: orphaned active - cancel
    function onClickCancelEdit() {
        if (active) {
            setDraftFirstName(active.firstName)
            setDraftLastName(active.lastName)
            container.player.service.cancelEditPlayer(active)
        }
    }

    const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (active) {
            const updatedPlayer = {
                ...active,
                firstName: draftFirstName,
                lastName: draftLastName
            }
            container.player.service.update(updatedPlayer)
        }
    }

    const toggleSettings = () => {
        setShowSettings(prevState => !prevState)
    }

    function lastPlayed(matches: Match[]): Date | undefined {
        if (matches && matches.length > 0) {
            return new Date(matches[matches.length - 1].date)
        }
    }

    if (!active) {
        return null;
    }

    return <section className="player-pane">
        <header className="player-pane-header">
            <div className="player-pane-header-title">
                {!(isPlayerUI(activeUI) && activeUI.isEditing)
                    ? <h3>{active.firstName} {active.lastName}</h3>
                    : (<form name="edit-player-form" onSubmit={handleSubmitEdit}>
                            <div className="edit-player-form-container">
                                <div className="edit-player-form-fieldset">
                                    <label htmlFor="first-name">First Name</label>
                                    <input
                                        type="text"
                                        id="first-name"
                                        value={draftFirstName}
                                        onChange={e => {
                                            setDraftFirstName(e.currentTarget.value)
                                        }}/>
                                </div>
                                <div className="edit-player-form-fieldset">
                                    <label htmlFor="last-name">Last Name</label>
                                    <input
                                        type="text"
                                        id="last-name"
                                        value={draftLastName}
                                        onChange={e => {
                                            setDraftLastName(e.currentTarget.value)
                                        }}/>
                                </div>
                                {/*TODO: Needs email*/}
                                <input type="submit" className="edit-player-form-submit"/>
                            </div>
                        </form>
                    )}

                <small>
                    {isPlayerUI(activeUI) && activeUI.isEditing ?
                        <button className="edit cancel" onClick={onClickCancelEdit}>Cancel</button>
                        : <button className="edit" onClick={onClickEdit}>(Edit)</button>}
                </small>
            </div>
            <button className="unfocus" onClick={() => container.player.service.cancelActive()}>&times;</button>
        </header>
        <nav className={`settings`}>
            <div className={`settings-container ${showSettings && 'active'}`}>
                <div className="settings-toggle-container">
                    <button className="settings-toggle" onClick={toggleSettings}>
                        <FontAwesomeIcon icon={faCog}/>
                    </button>
                </div>
                {showSettings && (
                    <ul className="settings-actions">
                        <li>
                            <button onClick={() => {
                                container.ui.service.activateModal(
                                    `Are you sure you want to delete ${active?.firstName} ${active?.lastName}`,
                                    () => container.player.service.delete(active)
                                )

                            }}>Delete
                            </button>
                        </li>
                        <li><ResetRatingButton container={container} active={active}/></li>
                    </ul>
                )}
            </div>
        </nav>
        <main>
            <section className="player-quick-stats">
                <dl>
                    <div>
                        <dt>Current Rating</dt>
                        <dd>{active.rating}</dd>
                    </div>
                    <div>
                        <dt>Last Played</dt>
                        <dd>{formatDate(lastPlayed(matches))}</dd>
                    </div>
                    <div>
                        <dt>Email</dt>
                        <dd>{active.email}</dd>
                    </div>
                </dl>
            </section>
            <section className="player-rating-history">
                <header><h3>Rating History</h3></header>
                <ResponsiveContainer width='99%' height={200}>
                    <LineChart data={ratingChartData}>
                        <Line type="monotone" dataKey="rating" stroke="#8884d8"/>
                        <CartesianGrid stroke="#ccc"/>
                        <XAxis/>
                        <YAxis domain={['auto', 'auto']}/>
                    </LineChart>
                </ResponsiveContainer>
            </section>
            <section className="player-match-history">
                {matchesLoading ? 'Loading Match History' : <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Result</th>
                        <th>Opponent</th>
                        <th>Rating Change</th>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {active && [...matches]
                        .reverse()
                        .map((match) => toMatchHistoryRow(active, match))
                        .map((row, i) =>
                            <tr key={i}>
                                <td>{row.date}</td>
                                <td>{row.winLose ? 'Won' : 'Lost'}</td>
                                <td>{row.opponent} ({row.opponentRatingAtTime})</td>
                                <td>{row.ratingBefore}</td>
                                <td>{row.ratingAfter}</td>
                                <td>{row.winLose ? <FontAwesomeIcon icon={faArrowUp}/> :
                                    <FontAwesomeIcon icon={faArrowDown}/>}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
            </section>
        </main>
    </section>
}

interface ResetRatingButtonProps {
    container: AppContainer
    active: Player
}

function ResetRatingButton({container, active}: ResetRatingButtonProps) {
    return <button onClick={() => {
        container.player.service.setTempRating(active.rating);
        container.ui.service.activateModalSlug(
            RESET_RATING_SLUG,
            () => {
                const updated: Player = {
                    ...active,
                    rating: container.player.query.tempRating
                }
                container.player.service.resetRating(updated)
                container.player.service.setTempRating(0)
            },
            () => container.player.service.setTempRating(0)
        )
    }}>
        Reset Rating
    </button>
}