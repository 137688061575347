import React from "react";
import {PlayerWatch} from "../domain/me/models";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faForward} from "@fortawesome/free-solid-svg-icons/faForward";

interface Props {
    playerWatch: PlayerWatch
}

export function PlayerBookmark({playerWatch}: Props) {
    if (!playerWatch.player) {
        return null
    }

    return <Link
        to={`/player/${playerWatch.playerId}`}
        className="player-bookmark"
        style={{
            textDecoration: 'none',
            color: 'black'
        }}>
        <header className="player-bookmark-info">
            <div
                className="player-bookmark-name">{playerWatch.player.firstName} {playerWatch.player.lastName}</div>
            <div className="player-bookmark-rating">{playerWatch.player.rating}</div>
        </header>
        <div className="player-bookmark-action"><FontAwesomeIcon icon={faForward}/>
        </div>
    </Link>
}