import React from "react";
import {ID} from "@datorama/akita";
import {LeagueEvent} from "../domain/leagueevent/models";
import './LeagueEventsList.css'

export type LeagueEventsListItem = {
    id: ID,
    displayText: string
}

interface LeagueEventsListProps {
    title: string
    leagueEvents: LeagueEventsListItem[]
    onClick: (id: ID) => any
}

export function LeagueEventsList(props: LeagueEventsListProps) {
    return <section className="league-events-list">
        <h3>{props.title}</h3>
        <ul>
            {props.leagueEvents.length === 0 && <li>None</li>}
            {props.leagueEvents.map((le, i) =>
                <li key={i}>
                    <button
                        onClick={() => {
                            props.onClick(le.id)
                        }}
                        className="transparent-button">
                        {le.displayText}
                    </button>
                </li>
            )}
        </ul>
    </section>
}

export function eventToLi(e: LeagueEvent) {
    return ({
        id: e.id,
        displayText: e.name === '' ? e.dateString : `${e.name} (${e.dateString})`
    })
}
