import React from "react";
import './ClickablePlayerList.css'
import {Player} from "../domain/player/models";

interface Props {
    players: Player[]
    onClick: (player: Player) => any
}

export function ClickablePlayerList(props: Props) {
    return <section className="clickable-player-list">
        {props.players.map((p, i) =>
            <button key={i} className="clickable-player" onClick={() => props.onClick(p)}>
                <div>{p.firstName}</div>
                <div>{p.lastName}</div>
                <div>{p.rating}</div>
            </button>
        )}
    </section>
}