import {SlugType} from "../../components/Modal";

export interface UIState {
    modalActive: boolean
    modalText: string
    slug: SlugType
    onConfirm: () => void
    onCancel: () => void
}

export function initialState(): UIState {
    return {
        modalActive: false,
        modalText: '',
        slug: '',
        onConfirm: () => {
        },
        onCancel: () => {
        },
    }
}