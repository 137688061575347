import {ID} from "@datorama/akita";
import {interval, Subject} from "rxjs";
import {
    CreateLeagueRequest,
    ListLeaguePermissionsResponse,
    ListLeaguesResponse,
    ShowLeagueResponse,
    UpdateLeaguePermissionsRequest,
    UpdateLeagueRequest
} from "../../api/types";
import {
    CreateLeague,
    DestroyLeague,
    ListLeaguePermissions,
    ListLeagues,
    ShowLeague,
    UpdateLeague,
    UpdateLeaguePermissions
} from "../../api/endpoints";
import {create, list, remove, show, update2} from "../../api/gateway";
import {debounce, switchMap, tap} from "rxjs/operators";
import {LeagueStore} from "./store";

export class LeagueGateway {
    readonly onList$ = new Subject<any>()
    readonly onShow$ = new Subject<ID>();
    readonly onCreate$ = new Subject<CreateLeagueRequest>();
    readonly onUpdate$ = new Subject<[ID, UpdateLeagueRequest]>();
    readonly onDestroy$ = new Subject<ID>();
    readonly onUpdatePermission$ = new Subject<[ID, UpdateLeaguePermissionsRequest]>();
    readonly onListPermissions$ = new Subject<ID>();
    readonly list$ = this.onList$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap(() => list<ListLeaguesResponse>(ListLeagues()))
    )
    readonly show$ = this.onShow$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap((id) => show<ShowLeagueResponse>(ShowLeague(id)))
    )
    readonly create$ = this.onCreate$.pipe(
        debounce(() => interval(500)),
        switchMap((req) => create<CreateLeagueRequest, ShowLeagueResponse>(CreateLeague(), req))
    )
    readonly update$ = this.onUpdate$.pipe(
        debounce(() => interval(500)),
        switchMap(([id, req]) => update2<UpdateLeagueRequest, ShowLeagueResponse>(UpdateLeague(id), req))
    )
    readonly destroy$ = this.onDestroy$.pipe(
        debounce(() => interval(500)),
        switchMap((id) => remove<any>(DestroyLeague(id)))
    )
    readonly listPermissions$ = this.onListPermissions$.pipe(
        debounce(() => interval(500)),
        switchMap((id) => list<ListLeaguePermissionsResponse>(ListLeaguePermissions(id)))
    )
    readonly updatePermission$ = this.onUpdatePermission$.pipe(
        debounce(() => interval(500)),
        switchMap(([leagueId, req]) => update2<UpdateLeaguePermissionsRequest, ListLeaguePermissionsResponse>(UpdateLeaguePermissions(leagueId), req))
    )

    constructor(private store: LeagueStore) {
    }
}
