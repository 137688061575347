import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import {AppContainer} from "../domain/AppContainer";
import {LeaguePlayersPrintView} from "./LeaguePlayersPrintView";

interface Props {
    container: AppContainer
}

export function PrintRouter({container}: Props) {
    let routeMatch = useRouteMatch()
    return <div>
        <Switch>
            <Route path={`${routeMatch.path}/league/:leagueId`}>
                <LeaguePlayersPrintView container={container}/>
            </Route>
            <Route path={routeMatch.path}>
                Invalid Route
            </Route>
        </Switch>
    </div>
}