import {QueryEntity} from "@datorama/akita";
import {UserState} from "./models";
import {UserStore} from "./store";

export class UserQuery extends QueryEntity<UserState> {
    readonly users$ = this.selectAll()
    readonly active$ = this.selectActive()
    readonly watchlist$ = this.select(state => state.watchList)

    constructor(protected store: UserStore) {
        super(store);
    }
}
