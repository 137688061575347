import {FormEvent} from "react";

export function noop(): void {
}

export function disable(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
}

export function countToArray(count: number): number[] {
    let result = []
    for (let i = 0; i < count; i++) {
        result.push(i);
    }
    return result;
}