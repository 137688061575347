import React, {useRef, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import "./PlayerForm.css"
import {playerRequest} from "../domain/player/models";
import {idToNumber} from "../util/types";

interface Props {
    container: AppContainer
    onDismiss: () => any
}

export function PlayerForm({container, onDismiss}: Props) {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [rating, setRating] = useState<string>(''); // TODO: number
    const nameInputRef = useRef<HTMLInputElement>(null)

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const activeLeague = container.league.query.getActive()
        if (activeLeague) {
            container.player.service.create(playerRequest(firstName, lastName, email, rating, idToNumber(activeLeague.id)), () => {
                setFirstName('')
                setLastName('')
                setEmail('')
                setRating('')
                if (nameInputRef.current) {
                    nameInputRef.current.focus()
                }
            });
        }
    }

    return (
        <section className="player-form">
            <header className="player-form-header">
                <h3>Add Player</h3>
                <button className="unfocus" onClick={() => onDismiss()}>&times;</button>
            </header>
            <form onSubmit={handleSubmit}>
                <div className="form-container">
                    <fieldset className="form-row">
                        <legend>Name</legend>
                        <div className="form-field-group">
                            <label htmlFor="first-name">First</label>
                            <input
                                type="text"
                                id="first-name"
                                ref={nameInputRef}
                                value={firstName}
                                onChange={e => setFirstName(e.currentTarget.value)}/>
                        </div>
                        <div className="form-field-group">
                            <label htmlFor="last-name">Last</label>
                            <input
                                type="text"
                                id="last-name"
                                value={lastName}
                                onChange={e => setLastName(e.currentTarget.value)}/>
                        </div>
                    </fieldset>
                    <div className="form-row form-field-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            id="email"
                            value={email}
                            onChange={e => setEmail(e.currentTarget.value)}/>
                    </div>
                    <div className="form-row form-field-group">
                        <label htmlFor="rating">Initial Rating</label>
                        <input
                            type="number"
                            id="rating"
                            value={rating}
                            onChange={e => setRating(e.currentTarget.value)}/>
                    </div>
                    <input className="player-form-submit" type="submit" value="Add"/>
                </div>
            </form>
        </section>
    );
}
