import {ID} from "@datorama/akita";
import {interval, Subject} from "rxjs";
import {debounce, switchMap, tap} from "rxjs/operators";
import {list, show} from "../../api/gateway";
import {ListPlayersResponse, ShowPlayerResponse} from "../../api/types";
import {ListPlayers, ShowPlayer} from "../../api/endpoints";
import {PlayerStore} from "./store";

export const PlayerRoutes = {
    list: (leagueId: ID) => `/api/leagues/${leagueId}/players`,
    create: () => `/api/players`,
    get: (id: ID) => `/api/players/${id}`,
    update: (id: ID) => `/api/players/${id}`,
    destroy: (id: ID) => `/api/players/${id}`,
    updateRating: (id: ID) => `/api/players/${id}/rating`,
}

export class PlayerGateway {
    readonly onList$ = new Subject<ID>()
    readonly onShow$ = new Subject<ID>()
    readonly list$ = this.onList$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap((id) => list<ListPlayersResponse>(ListPlayers(id))))
    readonly show$ = this.onShow$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap((id) => show<ShowPlayerResponse>(ShowPlayer(id))))

    constructor(private store: PlayerStore) {

    }
}