import {LeagueEventStore} from "./store";
import {LeagueEventService} from "./service";
import {LeagueEventQuery} from "./query";
import {LeagueContainer} from "../league";
import {LeagueEventGateway} from "./gateway";
import {PlayerContainer} from "../player";

export class LeagueEventContainer {
    readonly service: LeagueEventService;
    readonly query: LeagueEventQuery;
    readonly gateway: LeagueEventGateway;
    private readonly store: LeagueEventStore;

    constructor(playerContainer: PlayerContainer, leagueContainer: LeagueContainer) {
        this.store = new LeagueEventStore();
        this.query = new LeagueEventQuery(this.store, playerContainer.query);
        this.gateway = new LeagueEventGateway(this.query)
        this.service = new LeagueEventService(this.store, this.query, leagueContainer.query, this.gateway);
    }
}
