import {Iframe} from "../component-library/Iframe";
import {PageWrapper} from "../component-library/PageWrapper";
import React, {useEffect} from "react";
import {AppContainer} from "../domain/AppContainer";
import './RegisterClub.css'
import mixpanel from 'mixpanel-browser';

interface Props {
    container: AppContainer
}

export function RegisterClub(props: Props) {
    useEffect(() => {
        mixpanel.track('Register Club');
    }, [])

    return <PageWrapper container={props.container}>
        <header className="register-club-header">
            <h2>Register your club or league</h2>
        </header>
        <main className="anonymous-page-wrapper-body">
            <Iframe
                iframe={`<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfjeSxgty6l8Gd-kbuXQ4EU1cXkFVaHEDCmVQ35gc4LX4O0Rg/viewform?embedded=true" width="100%" height="891" frameBorder="0" marginHeight={0} marginWidth={0}>Loading...</iframe>`}/>
        </main>
    </PageWrapper>
}