import {Observable, Subscription} from "rxjs";
import {getEntityType} from "@datorama/akita";
import {useEffect, useState} from "react";

export function useEntityStore<T>(entity$: Observable<getEntityType<T>[]>) {
    const [entities, setEntities] = useState<getEntityType<T>[]>([])

    useEffect(() => {
        const s = entity$.subscribe(setEntities);
        return () => {
            s.unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return entities;
}

export function onEmit<T>(source$: Observable<T>, nextFn: (value: T) => void): Subscription {
    return source$.subscribe(nextFn, console.error);
}

export function useActiveEntity<T>(active$: Observable<T>) {
    const [active, setActive] = useState<T | undefined>();

    useEffect(() => {
        const s = onEmit(active$, setActive)
        return () => s.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return active
}

export function useObservable<T>(o$: Observable<T>) {
    const [value, setValue] = useState<T>();

    useEffect(() => {
        const s = onEmit(o$, setValue)
        return () => s.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return value
}

export function useObservableWithInitialValue<T>(o$: Observable<T>, initialValue: T) {
    const [value, setValue] = useState<T>(initialValue);

    useEffect(() => {
        const s = onEmit(o$, setValue)
        return () => s.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return value
}