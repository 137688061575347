import React, {useEffect} from "react";
import {AppContainer} from "../domain/AppContainer";
import {useObservable, useObservableWithInitialValue} from "../hooks/useEntityStore";
import {useFuseFilter} from "../hooks/useFuseFilter";
import {FieldsetInput} from "../component-library/Fieldsets";
import {useHistory} from "react-router-dom";
import {Player} from "../domain/player/models";
import './LeagueDashboard.css'
import mixpanel from "mixpanel-browser";
import {ShareButton} from "../component-library/ShareButton";

interface Props {
    container: AppContainer
}

export function LeagueDashboard({container}: Props) {
    const history = useHistory()
    const active = useObservable(container.league.query.active$)
    const players = useObservableWithInitialValue(container.player.query.players$, [])
    const [filteredPlayers, playerCriteria, setPlayerCriteria] = useFuseFilter<Player>(players, {keys: ["firstName", "lastName", "rating"]});

    function clickedRow(p: Player) {
        container.player.service.setActive(p.id)
        history.push(`/player/${p.id}`)
    }

    useEffect(() => {
        mixpanel.track('view league dashboard');
    }, [])

    return <section className="league-dashboard">
        {active && (
            <section className="league-info">
                <address>
                    {active.address !== '' && <div>{active.address}</div>}
                    {active.website !== '' && <div><a href={active.website}>{active.website}</a></div>}
                </address>
                <ShareButton toShare={window.location.href}/>
            </section>
        )}
        <section className="league-dashboard-players">
            <div className="league-dashboard-players-table-filter">
                <FieldsetInput
                    label={"Filter"}
                    id={"filter-players"}
                    value={playerCriteria}
                    onChange={(v) => setPlayerCriteria(v)}/>
            </div>
            <div className="league-dashboard-players-table-wrapper">
                <table>
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Rating</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredPlayers.map((p, i) =>
                        <tr key={i}>
                            <td onClick={() => clickedRow(p)}>{p.firstName}</td>
                            <td onClick={() => clickedRow(p)}>{p.lastName}</td>
                            <td onClick={() => clickedRow(p)}>{p.rating}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </section>
    </section>
}