import {AnonymousPageWrapper} from "../component-library/PageWrapper";
import {Unauthenticated} from "../components/Unauthenticated";
import React, {useEffect} from "react";
import './Register.css'
import {Iframe} from "../component-library/Iframe";
import mixpanel from 'mixpanel-browser';

export function Register() {
    useEffect(() => {
        mixpanel.track('Register');
    }, [])

    return <AnonymousPageWrapper>
        <div className="register">
            <header>
                <h2>Register</h2>
            </header>
            <Unauthenticated/>

            <section className="register-caveat">
                <header>
                    <h3>Don't have a gmail account?</h3>
                    <p>Get notified when we have your preferred login</p>
                </header>

                <Iframe
                    iframe={`<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe_3VtsY6KVEoYP-C3BmHs8uBHHU9i-3cSuMoTDoJOS3Nw0Qw/viewform?embedded=true" width="100%" height="677" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`}/>
            </section>
        </div>
    </AnonymousPageWrapper>

}