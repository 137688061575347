import React, {useEffect} from "react";
import {AppContainer} from "../domain/AppContainer";
import {PlayerForm} from "./PlayerForm";
import {MatchBuilder} from "./MatchBuilder";
import {PlayerPane} from "./PlayerPane";
import {Player} from "../domain/player/models";
import {useObservable} from "../hooks/useEntityStore";
import './PlayerPaneActions.css'

interface Props {
    container: AppContainer
    active: Player | undefined
    showPlayerForm: boolean
    setShowPlayerForm: (show: boolean) => any
    showMatchBuilder: boolean
    setShowMatchBuilder: (show: boolean) => any
}

export function PlayerPaneActions({container, active, showPlayerForm, setShowPlayerForm, showMatchBuilder, setShowMatchBuilder}: Props) {
    const matchBuilderPlayers = useObservable(container.match.query.matchBuilderPlayers$)

    useEffect(() => {
        if (matchBuilderPlayers && matchBuilderPlayers.length > 0) {
            setShowMatchBuilder(true);
        }
    }, [matchBuilderPlayers, setShowMatchBuilder])

    return <section className={"player-pane-actions"}>
        {active && <PlayerPane container={container}/>}
        {showMatchBuilder && <MatchBuilder container={container} onDismiss={() => setShowMatchBuilder(false)}/>}
        {showPlayerForm && <PlayerForm container={container} onDismiss={() => setShowPlayerForm(false)}/>}
        {!showMatchBuilder &&
        <button className="player-pane-show-button" onClick={() => setShowMatchBuilder(true)}>Add Match</button>}
        {!showPlayerForm &&
        <button className="player-pane-show-button" onClick={() => setShowPlayerForm(true)}>Add Player</button>}
    </section>
}

