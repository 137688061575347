import React from "react";
import {PageWrapper} from "../component-library/PageWrapper";
import './Contact.css'
import {Link} from "react-router-dom";
import {AppContainer} from "../domain/AppContainer";

interface Props {
    container: AppContainer
}

export function Contact(props: Props) {
    return <PageWrapper container={props.container}>
        <section className="contact">
            <header>
                <h2>Contact us</h2>
            </header>
            <main className="contact-body">
                <div>
                    <h3>Email us at</h3>
                    <a href="mailto:support@tournamentarc.freshdesk.com">support@tournamentarc.freshdesk.com</a>
                </div>
                <div>
                    <h3>For support inquiries</h3>
                    Visit our support page: <Link to={"/support"}>Support</Link>
                </div>
                <div>
                    <h3>Want to register your club?</h3>
                    Visit club registration: <Link to={"/register-club"}>Register club</Link>
                </div>
            </main>
        </section>
    </PageWrapper>
}