import React, {useEffect, useState} from 'react';
import {GroupPlayer, LeagueEvent, NewLeagueEventMatch} from "../domain/leagueevent/models";
import groupBy from 'lodash/groupBy';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSlash} from "@fortawesome/free-solid-svg-icons/faSlash";
import {faTimesCircle as faTimesCircleRegular} from "@fortawesome/free-regular-svg-icons/faTimesCircle";
import {faCheckCircle as faCheckCircleRegular} from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import {faTimesCircle as faTimesCircleSolid} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faCheckCircle as faCheckCircleSolid} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import './RecordResults.css';
import {AppContainer} from "../domain/AppContainer";
import {useObservableWithInitialValue} from "../hooks/useEntityStore";
import {ID} from "@datorama/akita";
import {FieldsetInput} from "../component-library/Fieldsets";
import {WorkPane} from "../component-library/WorkPane";


interface Props {
    container: AppContainer
    event: LeagueEvent
}

export function RecordResults({container, event}: Props) {
    const matches = useObservableWithInitialValue(container.leagueEvent.query.matches$, [])
    const groupPlayersByGroup = useObservableWithInitialValue(container.leagueEvent.query.groups$, {})
    const [matchesByGroupNumber, setMatchesByGroupNumber] = useState<{ [groupNumber: string]: NewLeagueEventMatch[] }>({})
    const [groupNumbers, setGroupNumbers] = useState<number[]>([])
    useEffect(() => {
        setMatchesByGroupNumber(groupBy(matches, 'groupNumber'));
    }, [matches])

    useEffect(() => {
        setGroupNumbers(Object.keys(matchesByGroupNumber).map(x => parseInt(x)));
    }, [matchesByGroupNumber])

    return <>
        <section className="league-events-body">
            <main className="league-events-body-main">
                <WorkPane sticky={true} className="event-info">
                    <h3>Round Robin Event</h3>
                    <main className="event-info-body">
                        <div className="event-info-row">
                            <FieldsetInput
                                label={"Name"}
                                id={"event-name"}
                                value={event.name}
                                onChange={(v) => {
                                    container.leagueEvent.service.updateName(v)
                                }}/>

                            <FieldsetInput
                                type={"date"}
                                label={"Date"}
                                id={"event-date"}
                                value={event.dateString}
                                onChange={(v) => {
                                    container.leagueEvent.service.updateDateString(v)
                                }}/>
                        </div>

                        <input
                            type="submit"
                            className="button"
                            value="Finish"
                            onClick={() => {
                                container.leagueEvent.service.setStatus("done")
                            }}/>
                    </main>
                </WorkPane>
                <nav className="league-events-results-legend">
                    <fieldset className="fieldset fieldset-info legend-skip">
                        <div id="skip-instructions"><FontAwesomeIcon icon={faSlash}/></div>
                        <label htmlFor="skip-instructions" className="bg-foreground">Skip</label>
                    </fieldset>
                    <fieldset className="fieldset fieldset-info legend-won">
                        <div id="won-instructions"><FontAwesomeIcon icon={faCheckCircleRegular}/></div>
                        <label htmlFor="won-instructions" className="bg-primary-light">Won</label>
                    </fieldset>
                    <fieldset className="fieldset fieldset-info legend-lost">
                        <div className="lost-instructions"><FontAwesomeIcon icon={faTimesCircleRegular}/></div>
                        <label htmlFor="lost-instructions" className="bg-secondary-light">Lost</label>
                    </fieldset>
                </nav>
                {groupNumbers.map((groupNumber, i) => {
                    if (groupNumber === 0) {
                        return null;
                    }

                    const groupMatches = matchesByGroupNumber[groupNumber]
                    const groupPlayers = groupPlayersByGroup[groupNumber]

                    return (
                        <div className="work-pane" key={`${groupNumber}_${i}`}>
                            <header>
                                <h4>Group {groupNumber}</h4>
                            </header>
                            <GroupResultTable
                                playerList={groupPlayers}
                                groupMatches={groupMatches}
                                onUpdateMatch={(matchId, winnerId) => container.leagueEvent.service.updateMatch(matchId, winnerId)}/>
                        </div>
                    )
                })}
            </main>
        </section>
    </>
}

interface GroupResultTableProps {
    playerList: GroupPlayer[]
    groupMatches: NewLeagueEventMatch[]
    onUpdateMatch: (matchId: ID, winnerId?: ID) => any
}

function GroupResultTable({playerList, groupMatches, onUpdateMatch}: GroupResultTableProps) {
    const [matchesByPlayerId, setMatchesByPlayerId] = useState<{ [playerId: string]: ID[] }>({})
    const [matchesByMatchId, setMatchesByMatchId] = useState<{ [matchId: string]: NewLeagueEventMatch[] }>({})

    useEffect(() => {
        const updatedMatchesByPlayerId: { [playerId: string]: ID[] } = {}
        for (let groupMatch of groupMatches) {
            const leftPlayer = groupMatch.players[0]
            const rightPlayer = groupMatch.players[1]
            if (!updatedMatchesByPlayerId[leftPlayer.id]) {
                updatedMatchesByPlayerId[leftPlayer.id] = [groupMatch.id]
            } else {
                updatedMatchesByPlayerId[leftPlayer.id].push(groupMatch.id)
            }

            if (!updatedMatchesByPlayerId[rightPlayer.id]) {
                updatedMatchesByPlayerId[rightPlayer.id] = [groupMatch.id]
            } else {
                updatedMatchesByPlayerId[rightPlayer.id].push(groupMatch.id)
            }
        }

        setMatchesByPlayerId(updatedMatchesByPlayerId);
        setMatchesByMatchId(groupBy(groupMatches, 'id'))
    }, [groupMatches])

    if (!playerList) {
        return null;
    }

    return <table className="result-table">
        <thead>
        <tr>
            <th/>
            {playerList.map((p, i) =>
                <th key={`header_${i}_${p.playerId}`}>
                    {p.player.firstName} {p.player.lastName}
                </th>
            )}
        </tr>
        </thead>
        <tbody>
        {playerList.map((p, i) => {
                let isDuplicateMatch = true;
                const playerMatches = matchesByPlayerId[p.playerId]
                if (!playerMatches) {
                    return null
                }

                return (
                    <tr className="result-table-row" key={`row_${i}_${p.playerId}`}>
                        <td className="result-table-name">
                            {i + 1}. {p.player.firstName} {p.player.lastName}
                        </td>
                        {playerList.map(o => {
                            if (p.playerId === o.playerId) {
                                isDuplicateMatch = false;
                                return <td className="result-table-null-cell" key={`${i}-self-row`}/>
                            }

                            const opponentMatches = matchesByPlayerId[o.playerId]
                            const matchingMatchId = playerMatches.find(m => opponentMatches.indexOf(m) !== -1)
                            if (!matchingMatchId) {
                                console.error('couldn\'t find match', matchingMatchId)
                                return null
                            }

                            const matchingMatch = matchesByMatchId[matchingMatchId][0]

                            return <WinLossCell
                                key={`${i}_${p.playerId}_${o.playerId}`}
                                player={p}
                                opponent={o}
                                isDuplicate={isDuplicateMatch}
                                winnerId={matchingMatch.winnerId}
                                onWin={() => onUpdateMatch(matchingMatchId, p.playerId)}
                                onLose={() => onUpdateMatch(matchingMatchId, o.playerId)}
                                onSkip={() => onUpdateMatch(matchingMatchId)}/>
                        })}
                    </tr>
                )
            }
        )}
        </tbody>
    </table>
}

interface WinLossCellProps {
    player: GroupPlayer
    opponent: GroupPlayer
    isDuplicate: boolean
    winnerId: ID
    onWin: () => any
    onLose: () => any
    onSkip: () => any
}

function WinLossCell({player, opponent, isDuplicate, winnerId, onWin, onLose, onSkip}: WinLossCellProps) {
    const cellId = `${player.playerId}-${opponent.playerId}-${isDuplicate}`
    return <td className="result-table-result-cell">
        <div className="result-table-result-cell-container">
            <label
                htmlFor={`skip-${cellId}`}
                className={`result-table-result-cell-skip ${winnerId === 0 && 'selected'}`}>
                <input
                    name={`win-lose-${cellId}`}
                    type="radio"
                    checked={winnerId === 0}
                    onChange={onSkip}
                    id={`skip-${cellId}`}/>
                <FontAwesomeIcon icon={faSlash}/>
            </label>
            <label
                htmlFor={`win-${cellId}`}
                className={`result-table-result-cell-win ${winnerId === player.playerId && 'selected'}`}>
                <input
                    name={`win-lose-${cellId}`}
                    type="radio"
                    checked={winnerId === player.playerId}
                    onChange={onWin}
                    id={`win-${cellId}`}/>
                {winnerId === player.playerId
                    ? <FontAwesomeIcon icon={faCheckCircleSolid}/>
                    : <FontAwesomeIcon icon={faCheckCircleRegular}/>}
            </label>
            <label
                htmlFor={`loss-${cellId}`}
                className={`result-table-result-cell-lose ${winnerId === opponent.playerId && "selected"}`}>
                <input
                    name={`win-lose-${cellId}`}
                    type="radio"
                    checked={winnerId === opponent.playerId}
                    onChange={onLose}
                    id={`loss-${cellId}`}/>
                {winnerId === opponent.playerId
                    ? <FontAwesomeIcon icon={faTimesCircleSolid}/>
                    : <FontAwesomeIcon icon={faTimesCircleRegular}/>}
            </label>
        </div>
    </td>
}
