import {MeStore} from "./store";
import {MeQuery} from "./query";
import {MeService} from "./service";
import {MeGateway} from "./gateway";

export class MeContainer {
    readonly query: MeQuery;
    readonly service: MeService;
    private readonly store: MeStore;

    constructor() {
        this.store = new MeStore()
        this.query = new MeQuery(this.store)
        const gateway = new MeGateway()
        this.service = new MeService(this.store, gateway)
    }
}
