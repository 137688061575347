import {interval, Subject} from "rxjs";
import {debounce, switchMap} from "rxjs/operators";
import {create, list, update2} from "../../api/gateway";
import {CreateUserWatch, DeleteUserWatch, ListUserWatches} from "../../api/endpoints";
import {
    ListWatchesResponse,
    UnwatchPlayerRequest,
    UnwatchPlayerResponse,
    WatchPlayerRequest,
    WatchPlayerResponse
} from "../../api/types";

export class MeGateway {
    readonly onWatch$ = new Subject<number>();
    readonly onUnwatch$ = new Subject<number>();
    readonly onListWatches$ = new Subject<any>();

    readonly watch$ = this.onWatch$.pipe(
        debounce(() => interval(500)),
        switchMap((playerId) => create<WatchPlayerRequest, WatchPlayerResponse>(CreateUserWatch(), {playerId}))
    )

    readonly listWatches$ = this.onListWatches$.pipe(
        debounce(() => interval(500)),
        switchMap(() => list<ListWatchesResponse>(ListUserWatches()))
    )

    readonly unwatch$ = this.onUnwatch$.pipe(
        debounce(() => interval(500)),
        switchMap((playerId) => update2<UnwatchPlayerRequest, UnwatchPlayerResponse>(DeleteUserWatch(), {playerId}))
    )
}
