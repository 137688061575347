import React from 'react';
import './Fieldsets.css';

interface FieldsetInputProps {
    label: string
    id: string
    value: string | number
    onChange: (value: string) => any
    type?: 'text' | 'date' | 'number' | 'datetime-local'
    disabled?: boolean
    className?: string
    placeholder?: string
}

export function FieldsetInput(props: FieldsetInputProps) {
    return <fieldset className={`fieldset fieldset-input ${props.className}`}>
        <label htmlFor={props.id}>{props.label}</label>
        <input
            type={props.type ? props.type : 'text'}
            id={props.id}
            disabled={props.disabled}
            value={props.value}
            placeholder={props.placeholder}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}/>
    </fieldset>
}

interface FieldsetInfoProps {
    label: string
    id: string
    value: string | number
    color?: 'primary' | 'secondary' | 'inverse'
}

export function FieldsetInfo(props: FieldsetInfoProps) {
    return <fieldset className={`fieldset fieldset-info ${props.color}`}>
        <label htmlFor={props.id}>{props.label}</label>
        <div id={props.id}>{props.value}</div>
    </fieldset>
}

interface FieldsetToggleProps {
    label: string
    id: string
    value: boolean
    onChange: () => any
    disabled?: boolean
    className?: string
}

export function FieldsetToggle(props: FieldsetToggleProps) {
    return <fieldset className={`fieldset fieldset-toggle ${props.className}`}>
        <label htmlFor={props.id}>{props.label}</label>
        <input
            type={'checkbox'}
            id={props.id}
            disabled={props.disabled}
            checked={props.value}
            onChange={() => props.onChange()}/>
    </fieldset>
}