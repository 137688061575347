import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import {useObservable, useObservableWithInitialValue} from "../hooks/useEntityStore";
import {Link, useHistory, useParams} from "react-router-dom";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {filterNullish} from "../util/operators";
import {lastPlayed, ratingChangesToChartData, RatingChartData, toMatchHistoryRow} from "../domain/match/utils";
import {formatDate} from "../util/dates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons/faArrowDown";
import './PlayerInfo.css'
import {CustomToolTip} from "../component-library/CustomToolTip";
import {Player} from "../domain/player/models";
import {Match} from "../domain/match/models";
import {ID} from "@datorama/akita";
import {faEye as faEyeSolid} from "@fortawesome/free-solid-svg-icons/faEye";
import {idToNumber} from "../util/types";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import mixpanel from "mixpanel-browser";
import {HoverTooltip, HoverTooltipContainer} from "../component-library/HoverTooltip";

interface Props {
    container: AppContainer
}

interface Params {
    playerId: string
}

export function PlayerInfo({container}: Props) {
    const history = useHistory()
    let {playerId} = useParams<Params>();
    const authed = useObservable(container.auth.query.authenticated$)
    const player = useObservable(container.player.query.selectActive())
    const matches = useObservableWithInitialValue(container.match.query.selectAll().pipe(filterNullish()), [])
    const ratingChanges = useObservableWithInitialValue(container.match.query.ratingChanges$, [])
    const watchList = useObservable(container.me.query.watchList$)

    const [ratingChartData, setRatingChartData] = useState<RatingChartData[]>([]);
    const [isWatching, setIsWatching] = useState<boolean>(false);

    useEffect(() => {
        mixpanel.track('view player');
        //TODO: Only check this specific watch (e.g., service.isWatching()). List all watches on homepage only
        container.me.service.listWatches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (player == null) {
            container.player.service.select(playerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, playerId])

    useEffect(() => {
        const chartData = ratingChangesToChartData(ratingChanges)
        setRatingChartData(chartData);
    }, [ratingChanges])

    useEffect(() => {
        if (watchList && watchList.map(p => p.playerId).indexOf(idToNumber(playerId)) !== -1) {
            setIsWatching(true)
        } else {
            setIsWatching(false)
        }
    }, [watchList, playerId])

    function clickedOpponent(opponentId: ID) {
        container.player.service.setActive(opponentId)
        history.push(`/player/${opponentId}`)
    }

    if (!player) {
        return <section className="league-player">
            Loading Player
        </section>
    }

    return <section className="league-player">
        <Link className="transparent-border-button-link" to={`/league/${player.leagueId}`}>Back to Player List</Link>
        <header className="league-router-header">
            <h2>{player.firstName} {player.lastName}</h2>
            <HoverTooltipContainer disabled={authed}>
                <button
                    className="button watch-button"
                    disabled={!authed}
                    onClick={() => {
                        console.log(`don't allow if not logged in`, `unwatch if already watch`)
                        if (player) {
                            if (isWatching) {
                                container.me.service.unwatch(player.id)
                            } else {
                                container.me.service.watch(player.id)
                            }
                        }
                    }}>
                    {isWatching
                        ? <><FontAwesomeIcon icon={faEyeSolid}/> Watching</>
                        : <><FontAwesomeIcon icon={faEye}/> Watch</>}
                </button>
                <HoverTooltip>You must be logged in to watch</HoverTooltip>
            </HoverTooltipContainer>
        </header>
        <section className="league-player-stats">
            <table>
                <tbody>
                <tr>
                    <td>Current Rating</td>
                    <td>{player.rating}</td>
                </tr>
                <tr>
                    <td>Last Played</td>
                    <td>{formatDate(lastPlayed(matches))}</td>
                </tr>
                </tbody>
            </table>
        </section>
        <section className="league-player-rating-history">
            <ResponsiveContainer width='100%' height={200}>
                <LineChart data={ratingChartData}>
                    <CartesianGrid stroke="#ccc"/>
                    <XAxis dataKey={"date"} tickFormatter={v => new Date(v).toLocaleDateString()}/>
                    <YAxis domain={['auto', 'auto']}/>
                    <Tooltip content={<CustomToolTip/>}/>
                    <Line type="monotone" dataKey="rating" stroke="#8884d8"/>
                </LineChart>
            </ResponsiveContainer>
        </section>
        <section className="league-player-match-history">
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Result</th>
                    <th>Opponent</th>
                    <th colSpan={3}>Rating Change</th>
                </tr>
                </thead>
                <tbody>
                {player && [...matches].reverse()
                    .map((match) => toMatchHistoryRow(player, match))
                    .map((row, i) =>
                        <tr key={i}>
                            <td onClick={() => clickedOpponent(row.opponentId)}>{row.date}</td>
                            <td onClick={() => clickedOpponent(row.opponentId)}>{row.winLose ? 'Won' : 'Lost'}</td>
                            <td onClick={() => clickedOpponent(row.opponentId)}>{row.opponent} ({row.opponentRatingAtTime})</td>
                            <td onClick={() => clickedOpponent(row.opponentId)}>{row.ratingAfter}</td>
                            <td onClick={() => clickedOpponent(row.opponentId)}>{row.winLose ?
                                <FontAwesomeIcon icon={faArrowUp}/> :
                                <FontAwesomeIcon icon={faArrowDown}/>}</td>
                        </tr>
                    )}
                {initialMatch(player, matches)}
                </tbody>
            </table>
        </section>
    </section>
}

function initialMatch(player: Player, matches: Match[]) {
    if (matches.length) {
        const matchHistoryRow = toMatchHistoryRow(player, matches[0])
        return <tr>
            <td colSpan={3}>Initial Rating</td>
            <td>{matchHistoryRow.ratingBefore}</td>
            <td/>
        </tr>
    }

    return <tr>
        <td colSpan={3}>Initial Rating</td>
        <td>{player.rating}</td>
        <td/>
    </tr>


}




