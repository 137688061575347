import {ID} from "@datorama/akita";

// V2 API
const apiv2 = `/api/v2`

//Users
const usersEndpoint = `${apiv2}/users`
const meEndpoint = `${usersEndpoint}/me`
const watchEndpoint = `${meEndpoint}/watch`
export const ListUsers = (query: string) => `${usersEndpoint}?search=${query}`
export const CreateUserWatch = () => watchEndpoint
export const ListUserWatches = () => watchEndpoint
export const DeleteUserWatch = () => watchEndpoint

//Leagues
const leaguesEndpoint = `${apiv2}/leagues`
const leagueEndpoint = (id: ID) => `${apiv2}/leagues/${id}`
export const ListLeagues = () => leaguesEndpoint
export const CreateLeague = () => leaguesEndpoint
export const ShowLeague = (id: ID) => leagueEndpoint(id)
export const UpdateLeague = (id: ID) => leagueEndpoint(id)
export const DestroyLeague = (id: ID) => leagueEndpoint(id)
export const ListLeaguePermissions = (id: ID) => leagueEndpoint(id) + '/permissions'
export const UpdateLeaguePermissions = (id: ID) => leagueEndpoint(id) + '/permissions'

//Players
const playersEndpoint = `${apiv2}/players`
const playerEndpoint = (id: ID) => `${apiv2}/players/${id}`
export const ListPlayers = (leagueId: ID) => `${leagueEndpoint(leagueId)}/players`
export const CreatePlayer = (id: ID) => playersEndpoint
export const ShowPlayer = (id: ID) => `${apiv2}/players/${id}`
export const UpdatePlayer = (id: ID) => playerEndpoint(id)
export const DestroyPlayer = (id: ID) => playerEndpoint(id)

//Matches
const matchesEndpoint = `${apiv2}/matches`
export const ListMatchesForPlayer = (playerId: ID) => `${playerEndpoint(playerId)}/matches`
export const RecordMatch = () => matchesEndpoint

// V1 API
export const ListLeagueEventsForLeague = (id: ID) => `/api/leagues/${id}/events`;
export const CreateLeagueEvent = () => '/api/leagueevents';
export const UpdateLeagueEvent = (id: ID) => `/api/leagueevents/${id}`;
export const GetParticipants = (id: ID) => `/api/leagueevents/${id}/participants`;
export const AddParticipant = (id: ID) => `/api/leagueevents/${id}/participants`;
export const RemoveParticipant = (leagueEventId: ID, playerId: ID) => `/api/leagueevents/${leagueEventId}/participants/${playerId}`;
export const UpdateParticipant = (id: ID, playerId: ID) => `/api/leagueevents/${id}/participants/${playerId}`;
export const AddLeagueEventMatch = (id: ID) => `/api/leagueevents/${id}/matches`;
export const BulkAddLeagueEventMatch = (id: ID) => `/api/leagueevents/${id}/bulk-matches`;
export const GetMatches = (id: ID) => `/api/leagueevents/${id}/matches`;
export const UpdateLeagueEventMatch = (id: ID, matchId: ID) => `/api/leagueevents/${id}/matches/${matchId}`

export const LeagueEventMatchesResource = (id: ID) => `/api/leagueevents/${id}/matches`
export const LeagueEventResultsResource = (id: ID) => `/api/leagueevents/${id}/results`
export const LeagueEventMatchResource = (id: ID, matchId: ID) => `/api/leagueevents/${id}/matches/${matchId}`

// Other
//Oauth2
export const Login = () => `/login`
export const Logout = () => `/logout`
export const UserInfo = () => `/api/oauth2/info`

// Old Routes Abstraction
export type Routes = {
    list: () => string
    get: (id: ID) => string
    create: () => string
    update: (id: ID) => string
    destroy: (id: ID) => string
}