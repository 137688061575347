import {AppContainer} from "../domain/AppContainer";
import {useEffect, useState} from "react";
import {useObservable} from "./useEntityStore";
import {ID} from "@datorama/akita";

export function useAuth(leagueId: ID, container: AppContainer): boolean {
    const permissions = useObservable(container.auth.query.select(state => state.permissions))
    const [canEdit, setCanEdit] = useState<boolean>(false)

    useEffect(() => {
        const thisLeaguePermissions = permissions?.filter(p => `${p.leagueId}` === leagueId)
        if (thisLeaguePermissions && thisLeaguePermissions.length > 0) {
            const abilities = thisLeaguePermissions.map(p => p.ability)
            if (abilities.indexOf('admin') !== -1) {
                setCanEdit(true)
            }
        } else {
            setCanEdit(false)
        }
    }, [leagueId, permissions])

    return canEdit
}
