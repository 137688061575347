import React, {useEffect} from 'react'
import {AppContainer} from "../domain/AppContainer";
import './AnonymousAccess.css'
import {AppTitle} from "../component-library/AppTitle";
import {useObservableWithInitialValue} from "../hooks/useEntityStore";
import {LeagueTileContainer} from "../component-library/LeagueTile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import playerImg from '../images/player-transparent@0.5x.png'
import clubImg from '../images/building-transparent@0.5x.png'
import {Link} from "react-router-dom";
import {Footer} from "../component-library/Footer";
import mixpanel from "mixpanel-browser";

interface Props {
    container: AppContainer
}

export function AnonymousAccess({container}: Props) {
    const leagues = useObservableWithInitialValue(container.league.query.leagues$, [])

    useEffect(() => {
        mixpanel.track('view public homepage')
    }, []);

    useEffect(() => {
        container.league.service.list();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={`App anonymous-access`}>
        <header className="page-header">
            <div className="header-row">
                <section className="page-header-item page-header-item-left">
                    <AppTitle/>
                </section>
                <section className="page-header-item page-header-item-right">
                    <nav className="page-header-nav">
                        <a className="transparent-button button-link login" href="/login">Log in</a>
                        <a className="button round-button button-link register" href="/register">Register</a>
                    </nav>
                </section>
            </div>
            <div className="header-row header-row-app-description">
                <div className="app-description">
                    <h2>Track table tennis matches & progress</h2>
                    <p className="explore-cta">Explore league results <a href="#explore-leagues"
                                                                         className="icon-link"><FontAwesomeIcon
                        icon={faArrowRight}/></a></p>
                    <p className="register-cta"><Link to="/register" className="text-link">Register</Link> to get
                        updates on recent matches</p>
                </div>
            </div>
        </header>

        <main className={`page-body`}>
            <div className="page-body-inner">
                <section className="explore-leagues">
                    <h3 id="explore-leagues">
                        Explore table tennis clubs
                    </h3>
                    <LeagueTileContainer leagues={leagues}/>
                </section>
                <section className="features">
                    <div className="feature">
                        <div className="feature-contents players">
                            <header className="image-container">
                                {/*TODO: Replace with SVG*/}
                                <img src={playerImg} alt="table tennis player"/>
                            </header>
                            <h3>Get updated on matches</h3>
                            <p><Link to="/register" className="text-link">Create an account</Link></p>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="feature-contents club-owners">
                            <header className="image-container">
                                <img src={clubImg} alt="building"/>
                            </header>
                            <h3>Manage a club league</h3>
                            <p><Link to="/register-club" className="text-link">Add your club</Link></p>
                        </div>
                    </div>
                </section>
            </div>
        </main>

        <Footer/>
    </div>
}