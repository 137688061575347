import {Link} from "react-router-dom";
import React from "react";
import './AppTitle.css'

interface Props {
    inverse?: boolean
}

export function AppTitle({inverse}: Props) {
    return <h1 className={`app-title ${inverse ? 'app-title-inverse' : ''}`}>
        <Link to="/" style={{textDecoration: 'none'}}>
            <span className="app-title-tournament">tournament</span><span
            className={"app-title-arc"}>arc</span>
        </Link>
    </h1>
}