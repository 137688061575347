import React from 'react';
import {AppContainer} from "../domain/AppContainer";
import {useObservable} from "../hooks/useEntityStore";
import './LeagueSettings.css'
import {NavLink, Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import {LeagueInfoSettings} from "../components/LeagueInfoSettings";
import {LeagueSensitiveSettings} from "../components/LeagueSensitiveSettings";
import {LeaguePermissionsSettings} from "../components/LeaguePermissionsSettings";

interface Props {
    container: AppContainer
}

interface Params {
    leagueId: string
}

export function LeagueSettings({container}: Props) {
    let routeMatch = useRouteMatch()
    let {leagueId} = useParams<Params>();
    const active = useObservable(container.league.query.active$)

    return <div className="league-settings">
        <nav className="settings-nav">
            <ul>
                <li>
                    <NavLink exact to={`/league/${leagueId}/settings`} activeClassName={"active"}>
                        League Info
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/league/${leagueId}/settings/permissions`} activeClassName={"active"}>
                        Permissions
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/league/${leagueId}/settings/sensitive`} activeClassName={"active"}>
                        Sensitive
                    </NavLink>
                </li>
            </ul>
        </nav>
        <section className="settings-form">
            <Switch>
                <Route exact path={`${routeMatch.path}`}>
                    <LeagueInfoSettings active={active} container={container}/>
                </Route>
                <Route path={`${routeMatch.path}/permissions`}>
                    <LeaguePermissionsSettings container={container} active={active}/>
                </Route>
                <Route path={`${routeMatch.path}/sensitive`}>
                    <LeagueSensitiveSettings container={container} active={active}/>
                </Route>
            </Switch>
        </section>
    </div>
}



