import {ID} from "@datorama/akita";
import {interval, Subject} from "rxjs";
import {debounce, switchMap, tap} from "rxjs/operators";
import {list} from "../../api/gateway";
import {MatchStore} from "./store";
import {ListMatchesForPlayer} from "../../api/endpoints";
import {ListMatchesResponse} from "../../api/types";

export class MatchGateway {
    readonly onListForPlayer$ = new Subject<ID>();
    readonly listForPlayer$ = this.onListForPlayer$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap((id) => list<ListMatchesResponse>(ListMatchesForPlayer(id)))
    )

    constructor(private readonly store: MatchStore) {
    }
}