import React, {PropsWithChildren} from "react";
import './HoverTooltip.css'

interface ContainerProps {
    disabled?: boolean
}

export function HoverTooltipContainer(props: PropsWithChildren<ContainerProps>) {
    return <div className={`hover-tooltip-container ${props.disabled ? 'tooltip-disabled' : 'tooltip-enabled'}`}>
        {props.children}
    </div>
}

interface Props {

}

export function HoverTooltip(props: PropsWithChildren<Props>) {
    return <span className="hover-tooltip">
        {props.children}
    </span>
}
