import {GetMatch, Match, RatingChange} from "./models";
import {ID} from "@datorama/akita";
import {Player} from "../player/models";
import {Match as ApiMatch} from "../../api/types";

export function convertGetMatchToMatch(getMatch: GetMatch): Match {
    const winner: Player = {
        id: getMatch.WinnerId,
        firstName: getMatch.WinnerFirstName,
        lastName: getMatch.WinnerLastName,
        rating: getMatch.WinnerRating
    }

    const loser: Player = {
        id: getMatch.LoserId,
        firstName: getMatch.LoserFirstName,
        lastName: getMatch.LoserLastName,
        rating: getMatch.LoserRating
    }

    return {
        id: getMatch.Id,
        leagueId: 0,
        players: [winner, loser],
        winner: winner,
        wins: 0,
        losses: getMatch.Losses,
        date: getMatch.Date,
        winnerRatingBefore: getMatch.WinnerRatingBefore,
        winnerRatingAfter: getMatch.WinnerRatingAfter,
        loserRatingBefore: getMatch.LoserRatingBefore,
        loserRatingAfter: getMatch.LoserRatingAfter,
    }
}

export function takePlayerRating(id: ID, m: Match): RatingChange {
    const didWin = `${m.winner.id}` === `${id}`;
    const before = didWin ? m.winnerRatingBefore : m.loserRatingBefore;
    const after = didWin ? m.winnerRatingAfter : m.loserRatingAfter;
    return {
        date: m.date,
        match: m,
        left: before,
        right: after
    }
}


export interface MatchHistoryRow {
    date: string
    winLose: boolean
    opponent: string
    opponentId: ID
    opponentRatingAtTime: number
    ratingBefore: number
    ratingAfter: number
}

function defaultMatchHistoryRow() {
    return {
        date: '',
        winLose: true,
        opponent: '',
        opponentId: 0,
        opponentRatingAtTime: 0,
        ratingBefore: 0,
        ratingAfter: 0,
    }
}

export function toMatchHistoryRow(player: Player, match: Match): MatchHistoryRow {
    if (!match) {
        return defaultMatchHistoryRow()
    }

    const otherPlayer = match.players.find(p => p.id !== player.id)
    const opponent = otherPlayer ? `${otherPlayer.firstName} ${otherPlayer.lastName}` : '';
    const opponentId = otherPlayer ? otherPlayer.id : 0
    const didWin = match.winner.id === player.id;
    const opponentRatingAtTime = didWin ? match.loserRatingBefore : match.winnerRatingBefore;
    const ratingBefore = didWin ? match.winnerRatingBefore : match.loserRatingBefore;
    const ratingAfter = didWin ? match.winnerRatingAfter : match.loserRatingAfter;
    return {
        date: new Date(match.date).toLocaleDateString(),
        winLose: didWin,
        opponent,
        opponentId,
        opponentRatingAtTime,
        ratingBefore,
        ratingAfter,
    }
}

export interface RatingChartData {
    players: string
    date: Date
    rating: number
}

export function ratingChangesToChartData(ratingChanges: RatingChange[]): RatingChartData[] {
    const ratingChartData = [];
    let prev: RatingChange = ratingChanges[0]
    if (ratingChanges.length > 0) {
        ratingChartData.push({
            players: '[Initial Rating]',
            date: prev.date,
            rating: prev.left
        });
    }

    for (let i = 0; i < ratingChanges.length; i++) {
        let curr = ratingChanges[i];

        if (i < ratingChanges.length - 1) {
            let next = ratingChanges[i + 1]
            const currDate = new Date(curr.date)
            const nextDate = new Date(next.date)

            console.log(
                'date compare',
                currDate, nextDate,
                currDate.getFullYear() === nextDate.getFullYear(),
                currDate.getMonth() === nextDate.getMonth(),
                currDate.getDay() === nextDate.getDay())

            // if(currDate.getFullYear() === nextDate.getFullYear()
            //     && currDate.getMonth() === nextDate.getMonth()
            //     && currDate.getDay() === nextDate.getDay()) {
            //     continue
            // }
        }

        ratingChartData.push({
            players: curr.match.players.map(p => `${p.firstName} ${p.lastName}`).join(', '),
            date: curr.date,
            rating: curr.right,
        })
    }

    return ratingChartData;
}

export function lastPlayed(matches: Match[]): Date | undefined {
    if (matches && matches.length > 0) {
        return new Date(matches[matches.length - 1].date)
    }
}

export function apiMatchToDomainMatch(match: ApiMatch): Match {
    return {
        id: match.id,
        leagueId: match.leagueId,
        players: [match.winner, match.loser],
        winner: match.winner,
        wins: 0,
        losses: 0,
        date: match.date,
        winnerRatingBefore: match.winnerRatingChange.before,
        winnerRatingAfter: match.winnerRatingChange.after,
        loserRatingBefore: match.loserRatingChange.before,
        loserRatingAfter: match.loserRatingChange.after,
    }
}
