import {LeagueStore} from "./store";
import {LeagueService} from "./service";
import {LeagueQuery} from "./query";
import {LeagueGateway} from "./gateway";
import {AuthService} from "../auth/service";

export class LeagueContainer {
    readonly service: LeagueService;
    readonly query: LeagueQuery;
    readonly gateway: LeagueGateway;
    private readonly store: LeagueStore;

    constructor(authService: AuthService) {
        this.store = new LeagueStore();
        this.query = new LeagueQuery(this.store);
        this.gateway = new LeagueGateway(this.store);
        this.service = new LeagueService(this.store, this.gateway, this.query, authService)
    }
}