import React, {useEffect, useState} from 'react';
import {GroupPlayer, LeagueEvent} from "../domain/leagueevent/models";
import {WorkPane} from "../component-library/WorkPane";
import {AppContainer} from "../domain/AppContainer";
import {useEntityStore, useObservable, useObservableWithInitialValue} from "../hooks/useEntityStore";
import {useFuseFilter} from "../hooks/useFuseFilter";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {countToArray} from "../util/etc";
import './GroupSetup.css'
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {Player, PlayerState} from "../domain/player/models";

interface Props {
    container: AppContainer
    event: LeagueEvent
}

export function GroupSetup({container, event}: Props) {
    const players = useEntityStore<PlayerState>(container.leagueEvent.query.availablePlayers$);
    const groups = useObservable<{ [groupNumber: number]: GroupPlayer[] }>(container.leagueEvent.query.groups$);
    const [groupNumbers, setGroupNumbers] = useState<number[]>([0]);
    useEffect(() => {
        setGroupNumbers(countToArray(event.groupCount + 1));
    }, [event.groupCount])

    return <>
        <section className="league-events-body">
            <header className="league-events-body-header">
                <h3>{event.name} ({event.dateString})</h3>
            </header>
            <main className="league-events-body-main">
                {groupNumbers && groupNumbers.map(n =>
                    <GroupBlock
                        onRemove={() => {
                        }}
                        key={n}
                        container={container}
                        groupNumbers={groupNumbers}
                        groups={groups || {}}
                        groupNumber={n}/>
                )}
            </main>
        </section>
        <section className="league-events-right">
            <button
                className="button"
                onClick={() => {
                    container.leagueEvent.service.setStatus("recording")
                }}>
                Start
            </button>
            <EventInfo container={container} event={event}/>
            <PlayerFinder availablePlayers={players}
                          onClick={(p: Player) => container.leagueEvent.service.addPlayerToEvent(p)}/>
            <AddPlayer/>
        </section>
    </>
}

interface GroupBlockProps {
    onRemove: () => any
    groupNumbers: number[]
    groupNumber: number
    container: AppContainer
    groups: { [groupNumber: number]: GroupPlayer[] }
}

export function GroupBlock(props: GroupBlockProps) {
    const groupPlayers = props.groups[props.groupNumber] || []

    let isUnassigned = false;
    if (props.groupNumber === 0) {
        isUnassigned = true;
    }

    if (isUnassigned) {
        return null
    }

    let isLast = false;
    if (props.groupNumber === props.groupNumbers.length - 1) {
        isLast = true;
    }

    return <div className="work-pane event-group" key={props.groupNumber}>
        <header className="event-group-header">
            <div className="event-group-info group">
                <div className="event-group-label">Group</div>
                <div className="event-group-data">{props.groupNumber}</div>
            </div>

            {isLast && <div className="event-group-info">
                <button className="transparent-button" onClick={() => props.onRemove()}>
                    <FontAwesomeIcon icon={faMinus}/> Remove Group
                </button>
            </div>}
        </header>
        <section className="event-group-players">
            {groupPlayers.map((gp, i) => <div key={i} className="event-group-players-row">
                <div className="event-group-players-cell">{gp.player.firstName} {gp.player.lastName}</div>
                <div className="event-group-players-cell">{gp.player.rating}</div>
                <div className="event-group-selector event-group-players-cell">
                    <select
                        name="group-selector"
                        id={`group-selector-${gp.playerId}-${props.groupNumber}`}
                        value={props.groupNumber}
                        onChange={(e) => {
                            props.container.leagueEvent.service.changeGroup(gp, parseInt(e.target.value))
                        }}>
                        {props.groupNumbers.map((g, i) => <option key={i} value={g}>{g === 0 ? 'None' : g}</option>)}
                        <option value={props.groupNumbers.length}>{props.groupNumbers.length}</option>
                    </select>
                </div>
                <div className="event-group-players-cell">
                    <button className="transparent-button" onClick={() => {
                        props.container.leagueEvent.service.removePlayerFromEvent(gp.player)
                    }}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
            </div>)}
        </section>

    </div>
}

interface EventInfoProps {
    container: AppContainer
    event: LeagueEvent
}

function EventInfo({container, event}: EventInfoProps) {
    const playersPerGroup = useObservableWithInitialValue(container.leagueEvent.query.playersPerGroup$, 0)
    return <WorkPane>
        <table>
            <tbody>
            <tr>
                <td>Name</td>
                <td>
                    <input
                        type="text"
                        value={event.name}
                        onChange={(e) => {
                            container.leagueEvent.service.updateName(e.target.value)
                        }}/>
                </td>
            </tr>
            <tr>
                <td>Date</td>
                <td>
                    <input
                        type="date"
                        value={event.dateString}
                        onChange={(e) => {
                            container.leagueEvent.service.updateDateString(e.target.value)
                        }}/>
                </td>
            </tr>
            <tr>
                <td>Players</td>
                <td>
                    <input
                        type="number"
                        value={event.participants.length}
                        disabled={true}/>
                </td>
            </tr>
            <tr>
                <td>Groups</td>
                <td>
                    <input
                        type="number"
                        value={event.groupCount}
                        onChange={(e) => {
                            container.leagueEvent.service.setGroups(parseInt(e.target.value))
                        }}/>
                </td>
            </tr>
            <tr>
                <td>Player Per Group</td>
                <td>
                    <input
                        type="number"
                        value={playersPerGroup}
                        disabled={true}/>
                </td>
            </tr>
            <tr>
                <td>Tables</td>
                <td>
                    <input
                        type="number"
                        value={event.tables}
                        onChange={(e) => {
                            container.leagueEvent.service.setTables(parseInt(e.target.value))
                        }}/>
                </td>
            </tr>
            </tbody>
        </table>
    </WorkPane>
}


interface PlayerFinderProps {
    availablePlayers: Player[]
    onClick: (v: Player) => any
}

function PlayerFinder(props: PlayerFinderProps) {
    const [filteredPlayers, playerCriteria, setPlayerCriteria] = useFuseFilter<Player>(props.availablePlayers, {keys: ["firstName", "lastName"]});


    return <div className="work-pane player-finder">
        <header className="work-pane-header">
            <h4>Available Players</h4>
        </header>
        <fieldset className="fieldset fieldset-input">
            <label htmlFor="filter">Filter</label>
            <input className="text-input" type="text" value={playerCriteria}
                   onChange={(e) => setPlayerCriteria(e.target.value)}/>
        </fieldset>
        {filteredPlayers.map((p, i) => <div>
            <button
                key={i}
                onClick={() => props.onClick(p)}
                className="transparent-border-button">{p.firstName} {p.lastName} ({p.rating})
            </button>
        </div>)}
    </div>
}

interface AddPlayerProps {
}

function AddPlayer(props: AddPlayerProps) {
    return <div className="work-pane">
        <header className="work-pane-header">
            <h4>Add Player</h4>
        </header>
        <form>
            <fieldset className="fieldset fieldset-input">
                <label htmlFor="add-player-first-name">First Name</label>
                <input type="text" className="input"/>
            </fieldset>
            <fieldset className="fieldset fieldset-input">
                <label htmlFor="add-player-last-name">Last Name</label>
                <input type="text" className="input"/>
            </fieldset>
            <fieldset className="fieldset fieldset-input">
                <label htmlFor="add-player-rating">Initial Rating</label>
                <input type="text" className="input"/>
            </fieldset>
            <input type="submit" className="button"/>
        </form>
    </div>
}