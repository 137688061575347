import {ActiveState, EntityState, ID} from "@datorama/akita";
import {idToNumber} from "../../util/types";

export interface Player {
    id: ID
    firstName: string
    lastName: string
    email?: string
    leagueId?: ID
    // TODO: number
    rating: number
    createdAt?: Date
    updatedAt?: Date
}

export interface ListPlayerResult {
    id: number
    firstName: string
    lastName: string
    email: {
        String: string
        Valid: boolean
    }
    rating: number
    createdAt: {
        Time: Date,
        Valid: boolean
    }
    updatedAt: {
        Time: Date,
        Valid: boolean
    }
}

export function isPlayer(x: any): x is Player {
    const player = x as Player;
    return player
        && player.id !== undefined
        && player.firstName !== undefined
        && player.lastName !== undefined
        && player.rating !== undefined;
}

export interface PlayerUI {
    isLoading: boolean
    isEditing: boolean
    isInMatchBuilder: boolean
    tempRating: number
}

export function initialPlayerUI(): PlayerUI {
    return {
        isLoading: false,
        isEditing: false,
        isInMatchBuilder: false,
        tempRating: 0
    }
}

export function isPlayerUI(x: any): x is PlayerUI {
    const playerUI = x as PlayerUI;
    return playerUI && playerUI.isEditing !== undefined && playerUI.isLoading !== undefined;
}

export interface PlayerRequest {
    firstName: string
    lastName: string
    email?: string
    rating: number
    leagueId: ID
}

export interface UpdatePlayerRequestJson {
    id: number
    firstName: string
    lastName: string
    rating: number
    leagueId: number
}

export function playerRequest(firstName: string, lastName: string, email: string, rating: string, leagueId: ID): PlayerRequest {
    return {
        firstName,
        lastName,
        email,
        rating: parseInt(rating, 10),
        leagueId,
    }
}

export interface PlayerState extends EntityState<Player>, ActiveState {
    ui: {
        ready: boolean
    }
}

export const initialState = {
    ui: {
        ready: false
    }
}

export interface PlayerUIState extends EntityState<PlayerUI> {
}

export function playerToUpdatePlayerRequestJson(active: Player): UpdatePlayerRequestJson {
    const id = typeof active.id === 'string' ? parseInt(active.id) : active.id

    return {
        id,
        rating: active.rating,
        firstName: active.firstName,
        lastName: active.lastName,
        leagueId: idToNumber(active.leagueId as number | string),
    }
}

export function listPlayerResultToPlayer(listPlayerResult: ListPlayerResult): Player {
    return {
        ...listPlayerResult,
        email: listPlayerResult.email.Valid ? listPlayerResult.email.String : undefined,
        createdAt: listPlayerResult.createdAt.Valid ? new Date(listPlayerResult.createdAt.Time) : undefined,
        updatedAt: listPlayerResult.updatedAt.Valid ? new Date(listPlayerResult.updatedAt.Time) : undefined,
    }
}