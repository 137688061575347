import React, {useEffect} from 'react';
import {AppContainer} from "../domain/AppContainer";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {AnonymousPageWrapper, PageWrapper} from "../component-library/PageWrapper";
import {UserDashboard} from "./UserDashboard";
import {LeagueRouter} from "./LeagueRouter";
import {PrintRouter} from "./PrintRouter";
import {PlayerInfo} from "./PlayerInfo";
import {useObservable} from "../hooks/useEntityStore";
import {AnonymousAccess} from "./AnonymousAccess";
import {ScrollToTop} from "../component-library/ScrollToTop";
import {Unauthenticated} from "../components/Unauthenticated";
import {Support} from "./Support";
import {Register} from "./Register";
import {Contact} from "./Contact";
import {RegisterClub} from "./RegisterClub";

interface Props {
    container: AppContainer
}

export function AppRouter({container}: Props) {
    const loggedIn = useObservable(container.auth.query.authenticated$)

    useEffect(() => {
        container.auth.service.get()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Router>
        <Switch>
            <Route exact path={"/"}>
                <ScrollToTop/>
                {loggedIn
                    ? <PageWrapper container={container}>
                        <UserDashboard container={container}/>
                    </PageWrapper>
                    : <AnonymousAccess container={container}/>
                }

            </Route>
            <Route path={"/print"}>
                <ScrollToTop/>
                <div>
                    <PrintRouter container={container}/>
                </div>
            </Route>
            <Route path={"/league/:leagueId"}>
                <LeagueRouter container={container}/>
            </Route>
            <Route path={`/player/:playerId`}>
                <ScrollToTop/>
                <PageWrapper container={container}>
                    <PlayerInfo container={container}/>
                </PageWrapper>
            </Route>
            <Route path={`/contact`}>
                <ScrollToTop/>
                <Contact container={container}/>
            </Route>
            <Route path={`/support`}>
                <ScrollToTop/>
                <Support container={container}/>
            </Route>
            <Route path={`/register`}>
                <ScrollToTop/>
                <Register/>
            </Route>
            <Route path={`/login`}>
                <ScrollToTop/>
                <AnonymousPageWrapper>
                    <header>
                        <h2>Log in</h2>
                    </header>
                    <Unauthenticated/>
                </AnonymousPageWrapper>
            </Route>
            <Route path={`/register-club`}>
                <ScrollToTop/>
                <RegisterClub container={container}/>
            </Route>
        </Switch>
    </Router>
}
