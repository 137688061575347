import React from 'react';
import "./Unauthenticated.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons/faGoogle";

export function Unauthenticated() {
    return <aside className="unauthenticated">
        <a href={`/api/oauth2/redirect`} className="transparent-border-button-link">
            <FontAwesomeIcon icon={faGoogle}/> Sign in with Google
        </a>
    </aside>
}