import {Player} from "../player/models";

export interface MeState {
    watchList: WatchList
    matchFeed: MatchFeed
}

export function initialState(): MeState {
    return {
        watchList: [],
        matchFeed: []
    }
}

type WatchList = PlayerWatch[]

export interface PlayerWatch {
    playerId: number
    player: Player
}

type MatchFeed = FeedMatch[]

interface FeedMatch {
    id: number
    leagueId: number
    winner: Player
    loser: Player
    date: Date
    winnerRatingChange: {
        before: number,
        after: number
    }
    loserRatingChange: {
        before: number,
        after: number
    }
}