export const dates = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const mons = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function dayToWord(day: number): string {
    switch (day) {
        case 0:
            return 'Sunday'
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
        default:
            throw new Error('invalid day')
    }
}

export function monthToWord(month: number): string {
    switch (month) {
        case 0:
            return 'January'
        case 1:
            return 'February'
        case 2:
            return 'March'
        case 3:
            return 'April'
        case 4:
            return 'May'
        case 5:
            return 'June'
        case 6:
            return 'July'
        case 7:
            return 'August'
        case 8:
            return 'September'
        case 9:
            return 'October'
        case 10:
            return 'November'
        case 11:
            return 'December'
        default:
            throw new Error('invalid month')
    }
}

export function formatDate(date?: Date): string {
    if (date) {
        const day = dayToWord(date.getDay())
        const month = monthToWord(date.getMonth())
        const d = date.getDate()
        const year = date.getFullYear()

        return `${day}, ${month} ${d}, ${year}`
    }

    return '';
}