import {Iframe} from "../component-library/Iframe";
import {PageWrapper} from "../component-library/PageWrapper";
import React from "react";
import './Support.css';
import {AppContainer} from "../domain/AppContainer";

interface Props {
    container: AppContainer
}

export function Support(props: Props) {
    return <PageWrapper container={props.container}>
        <div className="support-container">
            <header>
                <h2>Support</h2>
            </header>
            <Iframe iframe={`<script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
<style type="text/css" media="screen, projection">
\t@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
</style> 
<iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://tournamentarc.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Submit&submitThanks=Thank+you+for+your+feedback" scrolling="no" height="500px" width="100%" frameborder="0">Loading...
</iframe>`}/>
        </div>
    </PageWrapper>
}