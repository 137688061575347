import {MatchStore} from "./store";
import {MatchService} from "./service";
import {MatchQuery} from "./query";
import {PlayerContainer} from "../player";
import {MatchGateway} from "./gateway";

export class MatchContainer {
    readonly service: MatchService;
    readonly query: MatchQuery;
    private readonly store: MatchStore;

    constructor(private playerContainer: PlayerContainer) {
        this.store = new MatchStore();
        this.query = new MatchQuery(this.store, playerContainer.query)
        const gateway = new MatchGateway(this.store)
        this.service = new MatchService(this.store, this.query, gateway, playerContainer.service, playerContainer.query)
    }
}