import React, {PropsWithChildren} from 'react';
import './LeagueTile.css'
import {Link} from "react-router-dom";
import {League} from "../domain/league/models";
import image from '../images/mdttc-hero.png'

interface Props {
    league: League
}

export function LeagueTile({league}: Props) {
    return <Link to={`/league/${league.id}`} style={{textDecoration: 'none', color: 'black'}}>
        <div className="league-tile">
            <div className="picture-wrapper"><img src={image} alt="Maryland Table Tennis Center"/></div>
            <header className="league-tile-name">
                {league.name}
            </header>
        </div>
    </Link>
}

interface ContainerProps {
    leagues: League[]
}

export function LeagueTileContainer({leagues}: PropsWithChildren<ContainerProps>) {
    return <div className="league-tile-container">
        {leagues.map((l, i) => <LeagueTile league={l} key={i}/>)}
    </div>
}