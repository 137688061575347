import React from 'react';
import ReactDOM from 'react-dom';
import './styles'
import reportWebVitals from './reportWebVitals';
import {AppContainer} from "./domain/AppContainer";
import {akitaDevtools} from "@datorama/akita";
import {FULLSTORY_ORG_ID, sendToAnalytics} from "./util/analytics";
import {AppRouter} from "./pages/AppRouter";
import FullStory from "react-fullstory";
import mixpanel from 'mixpanel-browser';

ReactDOM.render(
    <React.StrictMode>
        {process.env.NODE_ENV === 'production' && <FullStory org={FULLSTORY_ORG_ID}/>}
        <AppRouter container={new AppContainer()}/>
    </React.StrictMode>,
    document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production') {
    akitaDevtools();
    mixpanel.init('e653dfa854127b1faa3a2fd8129d353e', {debug: true});
} else {
    mixpanel.init('e653dfa854127b1faa3a2fd8129d353e');
    mixpanel.track('initial load')
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
