import React from "react";
import './CustomToolTip.css'

interface Props {
    active?: any
    payload?: any
    label?: any
}

export function CustomToolTip({active, payload, label}: Props) {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">Date: {new Date(label).toDateString()}</p>
                <p className="players">Players: {payload[0].payload.players}</p>
                <p className="rating">Rating: {payload[0].value}</p>
            </div>
        );
    }

    return null;
}