import {combineQueries, Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {GroupInfoById, GroupPlayer, GroupPlayersByGroup, LeagueEventsState} from "./models";
import {LeagueEventStore} from "./store";
import {map} from "rxjs/operators";
import {filterNullish} from "../../util/operators";
import {aPerB} from "./utils";
import groupBy from 'lodash/groupBy';
import {Observable, pipe, UnaryFunction} from "rxjs";
import {PlayerQuery} from "../player/query";
import {Player} from "../player/models";

@QueryConfig({sortBy: 'dateString', sortByOrder: Order.DESC})
export class LeagueEventQuery extends QueryEntity<LeagueEventsState> {
    readonly events$ = this.selectAll();
    readonly active$ = this.selectActive();
    readonly participants$ = this.selectActive(e => e.participants);
    readonly matches$ = this.selectActive(e => e.matches);

    constructor(protected store: LeagueEventStore, readonly playerQuery: PlayerQuery) {
        super(store);
    }

    get availablePlayers$(): Observable<Player[]> {
        //TODO: Memoize array to map
        return combineQueries([
            this.playerQuery.players$,
            this.participants$
        ]).pipe(
            map(([allPlayers, participants]) => {
                if (participants) {
                    const participantsById: { [id: string]: boolean } = {}
                    participants.forEach(gp => participantsById[gp.playerId] = true)
                    return allPlayers.filter((p: Player) => !participantsById[p.id])
                }

                return allPlayers
            })
        )
    }

    get tablesPerGroup$() {
        return this.selectActive()
            .pipe(
                filterNullish(),
                map(e => aPerB(e.tables, e.groupCount))
            );
    }

    get playersPerGroup$() {
        return this.selectActive()
            .pipe(
                filterNullish(),
                map(e => aPerB(e.participants.length, e.groupCount))
            );
    }

    get groups$(): Observable<GroupPlayersByGroup> {
        return this.selectActive(e => e.participants)
            .pipe(
                filterNullish(),
                map(p => groupBy(p, 'groupNumber')),
            )
    }

    get groupInfo$(): Observable<GroupInfoById> {
        return this.selectActive(e => e.participants)
            .pipe(
                map(p => groupBy(p, 'group')),
                map(g => {
                    let result: GroupInfoById = {}
                    for (let n of Object.keys(g)) {
                        const groupNumber = parseInt(n);
                        if (groupNumber === 0) {
                            continue;
                        }

                        const groupRatings = g[groupNumber].map(gp => gp.player.rating)
                        const min = Math.min(...groupRatings)
                        const max = Math.max(...groupRatings)

                        result[groupNumber] = {
                            min,
                            max,
                        }
                    }

                    return result;
                })
            )
    }
}

export function mapGroupPlayersByGroup(): UnaryFunction<Observable<Observable<GroupPlayer[]>>, Observable<any>> {
    return pipe(
        map((p: Observable<GroupPlayer[]>) => groupBy(p, 'group'))
    )
}

//export function filterNullish<T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
//     return pipe(
//         filter(x => x != null) as OperatorFunction<T | null | undefined, T>
//     );
// }