import {GroupPlayer, LeagueEvent, LeagueEventMatch, LeagueEventProgress, LeaguePlayer, NewGroups} from "./models";
import {
    AddLeagueEventMatchRequest,
    GetAllEventsForLeagueResponse,
    LeagueEvent as ApiLeagueEvent
} from "../../api/types";
import {ID} from "@datorama/akita";
import {groupBy} from 'lodash'
import {Player} from "../player/models";

export function randomRating() {
    const min = 2000;
    const max = 3000;
    return Math.floor(Math.random() * (min - max) + max)
}

export const makePlayers = (number: number) => {

    return seedPlayers.slice(0, number);
}

export const getPlayers = (start: number, number?: number) => {
    if (!number) {
        number = seedPlayers.length - 1;
    }
    return seedPlayers.slice(start, number);
}

export const seedPlayers: LeaguePlayer[] = [
    {id: 1, name: "Fan Zhendong", group: 0, rating: randomRating()},
    {id: 2, name: "Ma Long", group: 0, rating: randomRating()},
    {id: 3, name: "Xu Xin", group: 0, rating: randomRating()},
    {id: 4, name: "Tomokazu Harimoto", group: 0, rating: randomRating()},
    {id: 5, name: "Lin Yun", group: 0, rating: randomRating()},
    {id: 6, name: "Lin Gaoyuan", group: 0, rating: randomRating()},
    {id: 7, name: "Dimitrij Ovtcharov", group: 0, rating: randomRating()},
    {id: 8, name: "Hugo Calderano", group: 0, rating: randomRating()},
    {id: 9, name: "Mattias Falck", group: 0, rating: randomRating()},
    {id: 10, name: "Timo Boll", group: 0, rating: randomRating()},
    {id: 11, name: "Liang Jingkun", group: 0, rating: randomRating()},
    {id: 12, name: "Jang Woojin", group: 0, rating: randomRating()},
    {id: 13, name: "Jeoung Youngsik", group: 0, rating: randomRating()},
    {id: 14, name: "Liam Pitchford", group: 0, rating: randomRating()},
    {id: 15, name: "Wang Chuqin", group: 0, rating: randomRating()},
    {id: 16, name: "Koki Niwa", group: 0, rating: randomRating()},
    {id: 17, name: "Patrick Franziska", group: 0, rating: randomRating()},
    {id: 18, name: "Simon Gauzy", group: 0, rating: randomRating()},
    {id: 19, name: "Wong Chun", group: 0, rating: randomRating()},
    {id: 20, name: "Jun Mizutani", group: 0, rating: randomRating()},
];

export function leaguePlayerToPlayer(p: LeaguePlayer): Player {
    const [firstName, lastName] = p.name.split(" ");

    return {
        id: p.id,
        firstName,
        lastName,
        email: "",
        rating: p.rating
    }
}

export function generateMatchupsFromParticipants(participants: GroupPlayer[]): AddLeagueEventMatchRequest[] {
    const leagueEventMatches: AddLeagueEventMatchRequest[] = [];
    const participantsByGroup = groupBy(participants, 'groupNumber')
    for (let groupNumber of Object.keys(participantsByGroup)) {
        const groupPlayers = participantsByGroup[groupNumber]
        const max = groupPlayers.length - 1;
        let left = 0;
        let right = max;
        while (left < max) {
            const leftPlayer = groupPlayers[left]
            const rightPlayer = groupPlayers[right--]
            leagueEventMatches.push({
                groupNumber: parseInt(groupNumber),
                players: {
                    left: leftPlayer.playerId,
                    right: rightPlayer.playerId
                }
            })

            if (left === right) {
                left++;
                right = max;
            }
        }
    }

    return leagueEventMatches;
}

export function convertGroupsToLeagueMatches(groups: NewGroups): LeagueEventMatch[] {
    const result: LeagueEventMatch[] = []
    for (let g of Object.keys(groups)) {
        const playersById = groups[g]
        const playerIds = Object.keys(playersById);
        let p1 = 0;
        let p2 = playerIds.length - 1;
        while (p1 < playerIds.length - 1) {
            const p1Id = playerIds[p1]
            const p2Id = playerIds[p2--]
            const p1Player = playersById[p1Id]
            const p2Player = playersById[p2Id];

            result.push({
                groupNumber: parseInt(g),
                match: {
                    id: 0,
                    players: [leaguePlayerToPlayer(p1Player), leaguePlayerToPlayer(p2Player)],
                    winner: {id: 0, firstName: '', lastName: '', email: '', rating: 0},
                    winnerRatingBefore: 0,
                    winnerRatingAfter: 0,
                    loserRatingBefore: 0,
                    loserRatingAfter: 0,
                    wins: 0,
                    losses: 0,
                    leagueId: 0,
                    date: new Date()
                }
            })

            if (p1 === p2) {
                p1++;
                p2 = playerIds.length - 1;
            }
        }
    }

    return result;
}

export function aPerB(a: number, b: number) {
    if (a === 0 || b === 0) {
        return 0
    }
    return Math.ceil(a / b)
}

export function playerToGroupPlayer(player: Player, group: number = 0): GroupPlayer {
    return {
        player,
        groupNumber: group,
        playerId: player.id,
    }
}

export function calculateTablesForGroup(n: number, tablesPerGroup: number): number[] {
    let tables: number[] = [];
    let tableNumber = n * tablesPerGroup - tablesPerGroup + 1;
    while (tables.length < tablesPerGroup) {
        tables.push(tableNumber++)
    }
    return tables;
}

export function responseToLeagueEvents(response: GetAllEventsForLeagueResponse): LeagueEvent[] {
    return response.map(toLeagueEvent);
}

export function statusToLeagueEventProgress(status: string): LeagueEventProgress {
    const statuses = ['not-started', 'setting-up', 'in-progress', 'recording', 'done'];
    if (statuses.indexOf(status) !== -1) {
        return status as LeagueEventProgress;
    }

    return "not-started"
}

export function offsetDate(date: Date) {
    // date.toLocaleDateString()
    const offset = date.getTimezoneOffset()
    return new Date(date.getTime() - (offset * 60 * 1000));
}

export function dateToDatestring(date: Date) {
    return date.toISOString().split('T')[0];
}

export function toLeagueEvent(le: ApiLeagueEvent): LeagueEvent {
    const dateString = dateToDatestring(new Date(le.date.Time));
    // const dateString = new Date(le.date.Time).toLocaleDateString();
    return {
        id: le.id as ID,
        leagueId: le.leagueId as ID,
        name: le.name,
        dateString: dateString,
        date: new Date(),
        status: statusToLeagueEventProgress(le.status),
        participants: [],
        tables: le.tables,
        groupCount: le.groupCount,
        matches: [],
    }
}
