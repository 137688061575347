import React from "react";
import {ID} from "@datorama/akita";
import {Link} from "react-router-dom";
import './LeagueTitle.css'

interface LeagueTitleProps {
    leagueId: ID
    name?: string
}

export function LeagueTitle({leagueId, name}: LeagueTitleProps) {
    return <h2 className="league-title">
        <Link to={`/league/${leagueId}`} style={{textDecoration: 'none', color: 'black'}}>{name}</Link>
    </h2>
}