import React, {useEffect} from 'react';
import './UserDashboard.css'
import {AppContainer} from "../domain/AppContainer";
import {useObservable, useObservableWithInitialValue} from "../hooks/useEntityStore";
import {NoRenderErrorBoundary} from "../component-library/ErrorBoundary";
import {LeagueTileContainer} from "../component-library/LeagueTile";
import {PlayerBookmark} from "../component-library/PlayerBookmark";
import {Snackbar} from "../component-library/Snackbar";
import mixpanel from "mixpanel-browser";

interface Props {
    container: AppContainer
}

export function UserDashboard({container}: Props) {
    const leagues = useObservableWithInitialValue(container.league.query.leagues$, [])
    const loggedIn = useObservable(container.auth.query.authenticated$)
    const permissions = useObservable(container.auth.query.select(state => state.permissions))
    const watchList = useObservable(container.me.query.watchList$)
    const matchFeed = useObservable(container.me.query.matchFeed$)

    useEffect(() => {
        container.league.service.list();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        mixpanel.track('view user dashboard')
    }, []);

    useEffect(() => {
        if (loggedIn) {
            container.me.service.listWatches()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn])

    useEffect(() => {
        permissions?.forEach(p => {
            container.league.service.get2(p.leagueId)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions])


    return (
        <div className="App LeagueList user-dashboard">
            <section className="league-tiles">
                <header className="league-tile-title">
                    <h2>Clubs</h2>
                </header>
                <LeagueTileContainer leagues={leagues}/>
            </section>


            <div className="feed-container">
                <section className="feed-container-section watch-list-players">
                    <header className="feed-container-header">
                        <h4>Players you're watching</h4>
                    </header>
                    <NoRenderErrorBoundary>
                        <main className="feed-container-body">
                            {(!watchList || watchList.length === 0) && (
                                <Snackbar level="info" showIcon={true}>
                                    To watch a player, click the "Watch" button in their profile page. You can find a
                                    profile page by clicking a league above, then clicking on a player's name.
                                </Snackbar>
                            )}
                            {watchList && watchList.map((w, i) =>
                                <PlayerBookmark key={i} playerWatch={w}/>
                            )}
                        </main>
                    </NoRenderErrorBoundary>
                </section>
                <section className="feed-container-section watch-list-recent-matches">
                    <header className="feed-container-header">
                        <h4>Recent Matches</h4>
                    </header>
                    <main className="feed-container-body">
                        {(!matchFeed || matchFeed.length === 0) && (
                            <Snackbar level="info" showIcon={true}>
                                Watch a player to see their most recent matches
                            </Snackbar>
                        )}
                        {matchFeed && matchFeed.map((m, i) =>
                            <div key={i} className="recent-match">
                                <div className="recent-match-players">
                                    <strong>{m.winner.firstName} {m.winner.lastName}</strong> v. {m.loser.firstName} {m.loser.lastName}
                                </div>
                                <div className="recent-match-date">
                                    {new Date(m.date).toDateString()}
                                </div>
                            </div>
                        )}
                    </main>
                </section>
            </div>
        </div>
    )
}
