import {Match, ResponseWrapper, UnwatchPlayerResponse, UserPlayerWatch, WatchPlayerResponse} from "../../api/types";
import {applyTransaction, arrayAdd, ID} from "@datorama/akita";
import {MeStore} from "./store";
import {idToNumber} from "../../util/types";
import {MeGateway} from "./gateway";
import mixpanel from "mixpanel-browser";

export class MeService {
    constructor(private store: MeStore, private gateway: MeGateway) {
        this.gateway.listWatches$.subscribe(r => this.onListWatches(r))
        this.gateway.watch$.subscribe(r => this.onWatch(r))
        this.gateway.unwatch$.subscribe(r => this.onUnwatch(r))
    }

    // TODO: Handle error / invalid
    onListWatches(r: ResponseWrapper<{ watches: UserPlayerWatch[], matches: Match[] }>) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.update({
                watchList: r.data.watches,
                matchFeed: r.data.matches,
            })
        })
    }

    // TODO: Handle error / invalid
    onWatch(r: WatchPlayerResponse) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.update({watchList: r.data.watches})
        })
    }

    onUnwatch(r: UnwatchPlayerResponse) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.update({watchList: r.data.watches})
        })
    }

    watch(playerId: ID) {
        mixpanel.track('watch');
        this.gateway.onWatch$.next(idToNumber(playerId))
        applyTransaction(() => {
            this.store.setLoading(true)
            this.store.update(({watchList}) => ({watchList: arrayAdd(watchList, {playerId})}))
        })
    }

    unwatch(playerId: ID) {
        this.gateway.onUnwatch$.next(idToNumber(playerId))
        applyTransaction(() => {
            this.store.setLoading(true)
            this.store.update(({watchList}) => ({
                watchList: watchList.filter(w => w.playerId !== playerId)
            }))
        })
    }

    listWatches() {
        this.gateway.onListWatches$.next()
        this.store.setLoading(true)
    }
}