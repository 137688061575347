import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './UserControlPanel.css'
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {User} from "../domain/auth/models";
import {faLifeRing} from "@fortawesome/free-solid-svg-icons/faLifeRing";
import {Link} from 'react-router-dom';

interface Props {
    onClose: () => void
    user?: User
}

export function UserControlPanel({onClose}: Props) {
    return <div className="user-panel">
        <button className="transparent-button user-panel-dismiss" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes}/>
        </button>
        <header className="user-panel-header">
            <h2>Settings</h2>
        </header>
        <main className="user-panel-body">
            <a href={`/api/oauth2/logout`} className="user-panel-action">
                <FontAwesomeIcon icon={faSignOutAlt}/> Log out
            </a>
        </main>
        <footer className="user-panel-footer">
            <div className="help">
                <Link to="/support" className="transparent-button" style={{color: 'white', textDecoration: 'none'}}>
                    <FontAwesomeIcon icon={faLifeRing}/> Help
                </Link>
            </div>
            <div className="links">
                <ul>
                    <li><Link to={"/contact"}>Contact</Link></li>
                    <li><Link to={"/support"}>Support</Link></li>
                    <li><Link to={"/register-league"}>Create League</Link></li>
                    <li><Link to={"/"}>Home</Link></li>
                </ul>
            </div>
        </footer>
    </div>
}