import React from "react";
import {AppContainer} from "../domain/AppContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons/faUserCircle";
import {useObservable} from "../hooks/useEntityStore";
import './SignInButton.css'

interface Props {
    container: AppContainer
    onClickUser: () => any
}

export function SignInButton({container, onClickUser}: Props) {
    const user = useObservable(container.auth.query.user$)
    const authenticated = useObservable(container.auth.query.authenticated$)

    if (!authenticated) {
        return <nav className='sign-in-button'>
            <a href="/login" className="transparent-border-button-link">
                Sign in <FontAwesomeIcon icon={faSignInAlt}/>
            </a>
        </nav>
    }

    return <nav className='sign-in-button'>
        <button onClick={() => onClickUser()} className="transparent-border-button">
            <FontAwesomeIcon icon={faUserCircle}/> {user?.firstName} {user?.lastName}
        </button>
    </nav>
}