import {LeagueEventStore} from "./store";
import {LeagueEventQuery} from "./query";
import {defaultLeagueEvent, GroupPlayer, LeagueEvent, LeagueEventProgress, NewLeagueEventMatch} from "./models";
import {applyTransaction, arrayAdd, arrayRemove, arrayUpdate, ID} from "@datorama/akita";
import {playerToGroupPlayer, responseToLeagueEvents, toLeagueEvent} from "./utils";
import {CreateLeagueEvent, ListLeagueEventsForLeague} from "../../api/endpoints";
import {tap} from "rxjs/operators";
import {LeagueQuery} from "../league/query";
import {create, list} from "../../api/gateway";
import {
    BulkAddLeagueEventMatchResponse,
    CreateLeagueEventRequest,
    CreateLeagueEventResponse,
    GetAllEventsForLeagueResponse,
    GetMatchesResponse
} from "../../api/types";
import {LeagueEventGateway} from "./gateway";
import {Player} from "../player/models";
import {idToNumber} from "../../util/types";

function toLeagueEventMatch(json: GetMatchesResponse): NewLeagueEventMatch {
    return {
        ...json,
    }
}

export class LeagueEventService {

    constructor(private store: LeagueEventStore, private query: LeagueEventQuery, private leagueQuery: LeagueQuery, private gateway: LeagueEventGateway) {
        this.gateway.put$.subscribe(this.httpObserver);
        this.gateway.removeParticipant$.subscribe(this.httpObserver);
        this.gateway.changeGroup$.subscribe(this.httpObserver)
        this.gateway.getParticipants$.subscribe({
            ...this.httpObserver,
            next: value => this.store.updateActive({participants: value})
        });
        this.gateway.addParticipant$.subscribe({
            ...this.httpObserver,
            next: value => this.store.updateActive(({participants}) => ({participants: arrayUpdate(participants, value.playerId, value, 'playerId')}))
        });
        this.gateway.addMatches$.subscribe({
            ...this.httpObserver,
            next: (json: BulkAddLeagueEventMatchResponse) => this.store.updateActive({matches: json.map(toLeagueEventMatch)})
        });
        this.gateway.getMatches$.subscribe({
            ...this.httpObserver,
            next: (json: GetMatchesResponse[]) => this.store.updateActive({matches: json.map(toLeagueEventMatch)})
        });
        this.gateway.updateMatch$.subscribe({
            ...this.httpObserver,
            next: value => console.warn('TODO: updateMatch$', value)
        });
        this.gateway.getResults$.subscribe({
            ...this.httpObserver,
            next: value => {
                this.store.updateActive({matches: value})
            }
        })
    }

    private get httpObserver() {
        return {
            error: (err: any) => {
                console.error('could not update league event', err);
            },
            complete: () => {
                this.store.setLoading(false);
            }
        };
    }

    getAllForLeague() {
        let leagueId = this.leagueQuery.getActiveId();
        if (leagueId) {
            list<GetAllEventsForLeagueResponse>(ListLeagueEventsForLeague(leagueId))
                .pipe(tap(() => this.store.setLoading(true)))
                .subscribe({
                    next: response => {
                        this.store.reset()
                        this.store.add(responseToLeagueEvents(response))
                    },
                    error: err => {
                        console.error('could not load league events', err);
                    },
                    complete: () => {
                        this.store.setLoading(false);
                    }
                })
        }
    }

    createLeagueEvent() {
        const leagueEvent = defaultLeagueEvent()
        const leagueId = this.leagueQuery.getActiveId();
        if (!leagueId) {
            return
        }

        // let active = this.query.getActive();

        leagueEvent.leagueId = leagueId;
        const request: CreateLeagueEventRequest = {
            ...leagueEvent,
            // ...active,
            leagueId: idToNumber(leagueId),
            date: new Date(),
        }

        create<CreateLeagueEventRequest, CreateLeagueEventResponse>(CreateLeagueEvent(), request)
            .pipe(tap(() => this.store.setLoading(true)))
            .subscribe({
                next: (response: CreateLeagueEventResponse) => {
                    const e = toLeagueEvent(response)
                    applyTransaction(() => {
                        this.store.add(e)
                        this.store.setActive(e.id)
                    });
                },
                error: err => {
                    console.error('could not create league event', err);
                },
                complete: () => {
                    this.store.setLoading(false);
                }
            });
    }

    selectLeagueEvent(e: LeagueEvent) {
        this.store.setActive(e.id);
        this.gateway.select$.next(null);
    }

    selectLeagueById(leagueEventId: ID) {
        this.store.setActive(leagueEventId);
        this.gateway.select$.next(leagueEventId)
    }

    updateName(name: string) {
        this.store.updateActive({name});
        this.gateway.update$.next(null);
    }

    updateDateString(dateString: string) {
        this.store.updateActive({dateString});
        this.gateway.update$.next(null);
    }

    addPlayerToEvent(player: Player) {
        this.store.updateActive(prevState => {
            const alreadyParticipating = prevState.participants.map(p => p.playerId).indexOf(player.id) !== -1
            if (alreadyParticipating) {
                return prevState;
            }

            return {
                participants: arrayAdd(prevState.participants, playerToGroupPlayer(player))
            }
        });
        this.gateway.addParticipant.next(player.id);
    }

    removePlayerFromEvent(p: Player) {
        this.store.updateActive(({participants}) => ({participants: arrayRemove(participants, p.id, 'playerId')}))
        this.gateway.removeParticipant.next(p.id)
    }

    setTables(tables: number) {
        this.store.updateActive({tables});
        this.gateway.update$.next(null);
    }

    setGroups(newGroupCount: number) {
        this.store.updateActive(({groupCount, participants}) => {
            let unassignedParticipants: ID[] = [];
            if (newGroupCount < groupCount) {
                unassignedParticipants = participants
                    .filter(p => p.groupNumber > newGroupCount)
                    .map(p => p.playerId);
            }

            return {
                groupCount: newGroupCount,
                participants: arrayUpdate(participants, unassignedParticipants, {groupNumber: 0}, 'playerId')
            }
        });

        this.gateway.update$.next(null);
    }

    setStatus(status: LeagueEventProgress) {
        this.store.updateActive({status});
        this.gateway.update$.next(null);

        if (status === "recording") {
            this.gateway.addMatches.next(null)
        }

        window.scrollTo(0, 0)
    }

    changeGroup(gp: GroupPlayer, groupNumber: number) {
        this.store.updateActive(({participants, groupCount}) => {
            let updatedGroupCount = groupCount
            if (groupNumber > groupCount) {
                updatedGroupCount = groupNumber;
            }
            return ({
                participants: arrayUpdate(participants, gp.playerId, {groupNumber: groupNumber}, 'playerId'),
                groupCount: updatedGroupCount
            })
        })
        this.gateway.changeGroup.next({playerId: gp.playerId, groupNumber})
    }

    autoAssign() {
        console.warn('TODO: auto-assign; implement me')
    }

    updateMatch(matchId: ID, winnerId?: ID) {
        this.store.updateActive(({matches}) => ({matches: arrayUpdate(matches, matchId, {winnerId: winnerId || 0})}))
        this.gateway.updateMatchSubject.next([matchId, winnerId || 0])
    }

    clear() {
        this.store.setActive(null)
    }

    getResults() {
        this.gateway.results$.next(null)
    }
}