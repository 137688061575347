import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareSquare} from "@fortawesome/free-solid-svg-icons/faShareSquare";
import React, {useEffect, useRef, useState} from "react";
import './ShareButton.css'
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";

interface Props {
    toShare: string
}

export function ShareButton(props: Props) {
    const [showCopy, setShowCopy] = useState<boolean>(false)
    const [copied, setCopied] = useState<boolean>(false)
    const textCopyRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setShowCopy(false)
                setCopied(false)
            }, 2000)
        }
    }, [copied])

    function onCopy(e: React.MouseEvent<HTMLButtonElement>) {
        if (textCopyRef.current) {
            textCopyRef.current.select()
            document.execCommand('copy')
            // textCopyRef
            // e.target.focus()
            setCopied(true)
        }
    }

    return <div className="share-button-container">
        <button className="share-button" aria-label={"Share"} onClick={() => {
            setShowCopy(prevState => !prevState)
        }}>
            <FontAwesomeIcon icon={faShareSquare}/> Share
        </button>
        {showCopy && (
            <div className={`share-button-copy-container ${copied ? 'copy-success' : ''}`}>
                <textarea ref={textCopyRef} value={props.toShare}/>
                <button onClick={(e) => onCopy(e)}><FontAwesomeIcon icon={faCopy}/></button>
            </div>
        )}
    </div>
}