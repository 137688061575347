import {EntityStore, EntityUIStore, StoreConfig} from "@datorama/akita";
import {initialPlayerUI, initialState, PlayerState, PlayerUIState} from "./models";

@StoreConfig({name: 'player', resettable: true})
export class PlayerStore extends EntityStore<PlayerState> {
    ui!: EntityUIStore<PlayerUIState>;

    constructor() {
        super(initialState);
        this.createUIStore().setInitialEntityState(initialPlayerUI)
    }

    isReady() {
        this.update({ui: {ready: true}})
    }
}