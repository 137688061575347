import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import './ResetRatingModal.css';

interface Props {
    container: AppContainer
}

export function ResetRatingModal({container}: Props) {
    const [tempRating, setTempRating] = useState<number>(0);
    useEffect(() => {
        const s = container.player.query.tempRating$.subscribe(setTempRating);
        return () => s.unsubscribe();
    }, [container.player.query])
    return <fieldset className="reset-rating-body">
        <label htmlFor="new-rating">New Rating</label>
        <input
            id="new-rating"
            type="number"
            value={tempRating}
            onChange={(e) => container.player.service.setTempRating(parseInt(e.target.value))}/>
    </fieldset>
}