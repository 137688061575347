import {EntityStore, StoreConfig} from "@datorama/akita";
import {MatchState} from "./models";
import {Player} from "../player/models";

function initialState() {
    return {
        ui: {
            matchBuilderPlayers: []
        }
    }
}

// TODO: put match entities on player ui
@StoreConfig({name: 'match', resettable: true})
export class MatchStore extends EntityStore<MatchState> {
    constructor() {
        super(initialState());
    }

    updateMatchBuilderPlayers(matchBuilderPlayers: Player[]) {
        this.update({ui: {matchBuilderPlayers}})
    }
}