import {ajax} from "rxjs/ajax";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

export function list<T>(endpoint: string) {
    return ajax.getJSON<T>(endpoint, {'Content-Type': 'application/json'})
}

export function show<T>(endpoint: string) {
    return list<T>(endpoint)
}

export function create<T, R>(endpoint: string, body?: T): Observable<R> {
    return ajax.post(endpoint, body, {'Content-Type': 'application/json'})
        .pipe(map(response => response.response))
}

export function update<T>(endpoint: string, body?: T) {
    return ajax.put(endpoint, body, {'Content-Type': 'application/json'})
        .pipe(map(response => response.response));
}

export function update2<T, R>(endpoint: string, body?: T): Observable<R> {
    return ajax.put(endpoint, body, {'Content-Type': 'application/json'})
        .pipe(map(response => response.response));
}

export function remove<T>(endpoint: string): Observable<T> {
    return ajax.delete(endpoint, {'Content-Type': 'application/json'})
        .pipe(map(response => response.response))
}
