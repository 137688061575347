import {QueryEntity} from "@datorama/akita";
import {League, LeagueState} from "./models";
import {LeagueStore} from "./store";

export class LeagueQuery extends QueryEntity<LeagueState> {
    readonly state$ = this.select();
    readonly state = this.getAll();
    readonly active$ = this.selectActive<League>()
    readonly leagues$ = this.selectAll()

    constructor(protected store: LeagueStore) {
        super(store);
    }
}