import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import './PlayerTable.css'
import {Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons/faPrint";
import {filterNullish} from "../util/operators";
import {ID} from "@datorama/akita";
import {FieldsetInput} from "../component-library/Fieldsets";
import {useFuseFilter} from "../hooks/useFuseFilter";
import {isPlayerUI, Player, PlayerUI} from "../domain/player/models";

interface Props {
    container: AppContainer
}

export function PlayerTable({container}: Props) {
    const [players, setPlayers] = useState<Player[]>([]);
    const [active, setActive] = useState<Player | undefined>();
    const [numPlayersInMatch, setNumPlayersInMatch] = useState<number>(0);
    const [currentLeague, setCurrentLeague] = useState<ID>(0);

    const [filteredPlayers, playerCriteria, setPlayerCriteria] = useFuseFilter<Player>(players, {keys: ["firstName", "lastName", "rating"]});

    useEffect(() => {
        const subs: Subscription[] = []
        subs.push(container.player.query.selectAll().subscribe(setPlayers));
        subs.push(container.player.query.selectActive().subscribe(setActive));
        subs.push(container.match.query.matchBuilderPlayers$.pipe(map(x => x.length)).subscribe(setNumPlayersInMatch))
        subs.push(container.league.query.active$.pipe(filterNullish(), map(l => l.id)).subscribe(setCurrentLeague))
        return () => {
            subs.forEach(s => s.unsubscribe());
        }
    }, [container.player.query, container.match.query, container.league.query]);

    function clickedRow(player: Player) {
        return () => {
            container.player.service.setActive(player.id);
        }
    }

    return (
        <div className="column-contents">
            <header className="player-table-header">
                <div className="player-table-header-title player-table-header-column">
                    <FieldsetInput
                        label={"Filter"}
                        id={"filter-players"}
                        value={playerCriteria}
                        onChange={(v) => setPlayerCriteria(v)}/>
                </div>
                <div className="player-table-header-actions player-table-header-column">
                    <a
                        href={`/print/league/${currentLeague}`}
                        target="_blank"
                        rel="noreferrer"
                        className="transparent-border-button-link player-table-header-action">
                        <FontAwesomeIcon icon={faPrint} textRendering={"Print"}/> Print
                    </a>
                </div>
            </header>
            <table className="player-table">
                <thead>
                <tr>
                    <th className="align-left">First Name</th>
                    <th className="align-left">Last Name</th>
                    <th className="align-right">Rating</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {filteredPlayers.map(player => {
                        let playerUI = container.player.query.ui.getEntity(player.id);
                        return <tr key={player.id} className={`${active && active.id === player.id ? 'selected' : ''}`}>
                            <td onClick={clickedRow(player)}>{player.firstName}</td>
                            <td onClick={clickedRow(player)}>{player.lastName}</td>
                            <td onClick={clickedRow(player)} className="align-right">{player.rating}</td>
                            <td className="align-right">
                                <input
                                    type="checkbox"
                                    className="add-player-checkbox"
                                    disabled={numPlayersInMatch >= 2 && !(playerUI as PlayerUI).isInMatchBuilder}
                                    checked={(isPlayerUI(playerUI) && playerUI.isInMatchBuilder) || false}
                                    onChange={() => {
                                        let ui = container.player.query.ui.getEntity(player.id);
                                        if (isPlayerUI(ui) && !ui.isInMatchBuilder) {
                                            container.match.service.addToMatch(player);
                                        } else {
                                            container.match.service.removeFromMatch(player);
                                        }
                                    }}/>
                            </td>
                        </tr>
                    }
                )}
                </tbody>
            </table>
        </div>
    )
}