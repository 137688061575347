import {ActiveState, EntityState, ID} from "@datorama/akita";
import {Permission} from "../../api/types";
import {Player} from "../player/models";

export interface League {
    id: ID
    name: string
    isPublic: boolean
    address: string
    website: string
    imageUrl: string
    permissions: Permission[]
    players: Player[]

}

export function isLeague(x: any): x is League {
    return (x as League).name !== undefined && (x as League).id !== undefined
}

export function emptyLeague(): League {
    return {
        id: 0,
        name: '',
        isPublic: false,
        address: '',
        website: '',
        imageUrl: '',
        permissions: [],
        players: [],
    }
}

export interface LeagueRequest {
    name: string
}

export interface LeagueState extends EntityState<League>, ActiveState {
}