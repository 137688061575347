import React, {ReactNode} from 'react';
import './SlidingPanel.css';

interface Props {
    open: boolean
    children: ReactNode
}

export function SlidingPanel({open, children}: Props) {
    return <div className={`sliding-panel-wrapper ${open ? 'open' : 'closed'}`}>
        <div className="panel">
            {children}
        </div>
    </div>
}