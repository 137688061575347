import {League} from "../domain/league/models";
import {AppContainer} from "../domain/AppContainer";
import {WorkPane, WorkPaneHeader} from "../component-library/WorkPane";
import React, {useEffect, useState} from "react";
import {useObservableWithInitialValue} from "../hooks/useEntityStore";
import {FieldsetInput, FieldsetToggle} from "../component-library/Fieldsets";
import {PermissionAbility} from "../api/types";
import './LeaguePermissionsSettings.css'


interface LeaguePermissionsSettingsProps {
    active?: League
    container: AppContainer
}

export function LeaguePermissionsSettings({active, container}: LeaguePermissionsSettingsProps) {
    const users = useObservableWithInitialValue(container.user.query.users$, [])
    const [userFilter, setUserFilter] = useState<string>('')

    useEffect(() => {
        container.league.service.listPermissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        container.user.service.search(userFilter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userFilter])

    return <WorkPane className="league-permissions-settings">
        <WorkPaneHeader>Permissions</WorkPaneHeader>
        <FieldsetToggle
            label={"Make league public?"}
            id={"public"}
            value={active?.isPublic || false}
            onChange={() => {
                container.league.service.toggleVisibility()
            }}/>
        <table className="permissions-table table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Resource</th>
                <th>Role</th>
            </tr>
            </thead>
            <tbody>
            {active && active.permissions && active.permissions.map((p, i) =>
                <tr key={i}>
                    <td>{p.user.firstName} {p.user.lastName}</td>
                    <td>{p.resource}</td>
                    <td>
                        <select
                            value={p.ability}
                            onChange={(e) => {
                                container.league.service.updatePermission(p, e.target.value as PermissionAbility)
                            }}>
                            <option value="none">None</option>
                            <option value="read_only">Read Only</option>
                            <option value="read_write">Read/Write</option>
                            <option value="admin">Admin</option>
                        </select>
                    </td>
                </tr>
            )}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={3}>
                    <div>
                        <FieldsetInput
                            label={"Find user"}
                            id={"find-user"}
                            value={userFilter}
                            onChange={(val) => {
                                setUserFilter(val)
                            }}/>
                    </div>
                    <div>
                        {users.length > 0 && <p>Click on the user's name to add</p>}
                        <ul>
                            {users.map((u, i) =>
                                <li key={i}>
                                    <button
                                        className="link-button"
                                        onClick={() => {
                                            container.league.service.addRole(u.id)
                                        }}>
                                        + {u.firstName} {u.lastName} ({u.email})
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>

                </td>
            </tr>
            </tfoot>
        </table>
    </WorkPane>
}