import {Observable, Subject} from "rxjs";
import {Permission, ResponseWrapper} from "../../api/types";
import {User} from "./models";
import {switchMap, tap, throttleTime} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {AuthStore} from "./store";
import {applyTransaction} from "@datorama/akita";
import {identify} from "react-fullstory";
import mixpanel from "mixpanel-browser";

export class AuthService {
    private readonly request = new Subject<any>()
    readonly request$: Observable<ResponseWrapper<{ user: User, permissions: Permission[] }>> = this.request.pipe(
        throttleTime(500),
        switchMap(() => ajax.getJSON<ResponseWrapper<{ user: User, permissions: Permission[] }>>(`/api/oauth2/info`)),
        tap(() => this.authStore.setLoading(true)));

    constructor(private authStore: AuthStore) {
        this.request$.subscribe({
            next: (response) => {
                applyTransaction(() => {
                    this.authStore.setLoading(false);

                    if (response.valid) {
                        this.authStore.update({
                            checked: true,
                            user: response.data.user,
                            authenticated: true,
                            permissions: response.data.permissions,
                        })

                        if (process.env.NODE_ENV === 'production') {
                            mixpanel.track('login');
                            mixpanel.identify(`${response.data.user.id}`)

                            identify(`${response.data.user.id}`, {
                                firstName: response.data.user.firstName,
                                lastName: response.data.user.lastName,
                                source: response.data.user.source
                            });
                        }
                    } else {
                        this.authStore.update({checked: true, authenticated: false})
                    }
                })
            },
            error: (e) => {
                applyTransaction(() => {
                    this.authStore.update({checked: true, authenticated: false});
                    this.authStore.setLoading(false);
                })
            },
        });
    }


    get() {
        this.request.next(null);
    }
}