import {AuthStore} from "./store";
import {AuthService} from "./service";
import {AuthQuery} from "./query";

export class AuthContainer {
    readonly service: AuthService;
    readonly query: AuthQuery;
    private readonly store: AuthStore;

    constructor() {
        this.store = new AuthStore()
        this.service = new AuthService(this.store);
        this.query = new AuthQuery(this.store);
    }
}