import {AppTitle} from "./AppTitle";
import {Link} from "react-router-dom";
import React from "react";
import './Footer.css'

export function Footer() {
    return <footer className="page-footer">
        <header className={"page-footer-section page-footer-header"}>
            <AppTitle inverse={true}/>
        </header>
        <div className={"page-footer-section"}>
            <nav>
                <ul>
                    <li><Link to={"/support"}>Support</Link></li>
                    <li><Link to={"/contact"}>Contact</Link></li>
                    <li><Link to={"/login"}>Sign in</Link></li>
                    <li><Link to={"/register"}>Register</Link></li>
                    <li><Link to={"/register-club"}>Register a club</Link></li>
                </ul>
            </nav>
        </div>
    </footer>
}