import {LeagueEventMatch, LeaguePlayer, NewGroups} from "./models";
import {convertGroupsToLeagueMatches, getPlayers, leaguePlayerToPlayer, makePlayers, seedPlayers} from "./utils";
import {matchStatus} from "./actions";

export interface State {
    name: string
    date: string
    state: 'not-started' | 'setting-up' | 'in-progress' | 'recording' | 'done'
    //Why both?
    numTables: number
    //Why both?
    numGroups: number
    playersPerGroup: number
    playerCount: number
    leaguePlayers: LeaguePlayer[]
    availablePlayers: LeaguePlayer[]
    groups: NewGroups
    tables: number
    matches: LeagueEventMatch[]
}

export const initialState: State = {
    name: '',
    date: new Date().toLocaleDateString('en-CA'),
    state: 'not-started',
    numTables: 0,
    numGroups: 0,
    playersPerGroup: 0,
    playerCount: 0,
    leaguePlayers: [],
    availablePlayers: [],
    groups: {},
    tables: 0,
    matches: [],
}

export interface Action<T> {
    type: 'initialize'
        | 'clear'
        | 'initialize-mock-active'
        | 'initialize-mock-done'
        | 'setup'
        | 'start'
        | 'end'
        | 'complete'
        | 'add-player-to-league'
        | 'remove-player-from-league'
        | 'change-group'
        | 'set-players-per-group'
        | 'set-num-groups'
        | 'set-tables'
        | 'change-date'
        | 'change-name'
        | 'update-match'
    data: T
}

export function reducer(prevState: State, action: Action<any>): State {

    switch (action.type) {
        case 'initialize':
            return initialize(prevState, action);
        case 'clear':
            return initialState
        case 'initialize-mock-active':
            return {
                ...prevState,
                name: "Friday League",
                date: "2021-09-03",
                state: 'setting-up',
                numTables: 4,
                numGroups: 2,
                playersPerGroup: 4,
                playerCount: 8,
                leaguePlayers: makePlayers(8),
                availablePlayers: getPlayers(9, 20),
                groups: {
                    1: {
                        0: seedPlayers[0],
                        1: seedPlayers[1],
                        2: seedPlayers[2],
                        3: seedPlayers[3],
                    }, 2: {
                        0: seedPlayers[4],
                        1: seedPlayers[5],
                        2: seedPlayers[6],
                        3: seedPlayers[7],
                    }
                },
                tables: 2
            }
        case 'initialize-mock-done':
            return {
                ...prevState,
                state: "done",
                name: action.data.name,
                date: action.data.date,
            }
        case 'setup':
            return setup(prevState, action);
        case "start":
            return start(prevState, action);
        case "end":
            return end(prevState, action);
        case "complete":
            return complete(prevState, action);
        case "change-name":
            return changeName(prevState, action);
        case "change-date":
            return changeDate(prevState, action);
        case "set-num-groups":
            return setNumGroups(prevState, action);
        case "set-players-per-group":
            return setPlayerPerGroup(prevState, action);
        case 'set-tables':
            return setTables(prevState, action);
        case "add-player-to-league":
            return addPlayerToLeague(prevState, action);
        case "remove-player-from-league":
            return removePlayerFromLeague(prevState, action);
        case "change-group":
            return changeGroup(prevState, action);
        case "update-match":
            return updateMatch(prevState, action);
        default:
            throw new Error();
    }
}

function rebalance(groups: NewGroups, numGroups: number): NewGroups {
    console.error('implement rebalance');
    return groups;
}

function initialize(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        availablePlayers: action.data.seedPlayers,
        state: "not-started"
    };
}

function setup(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        state: 'setting-up'
    }
}

function start(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        state: "in-progress"
    }
}

function end(prevState: State, action: Action<any>): State {
    const matches = convertGroupsToLeagueMatches(prevState.groups)

    return {
        ...prevState,
        state: "recording",
        matches
    }
}

function complete(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        state: "done"
    }
}

function changeName(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        name: action.data.name
    }
}

function changeDate(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        date: action.data.date
    }
}

function setNumGroups(prevState: State, action: Action<any>): State {
    const numGroups = action.data.numGroups;
    let groups = prevState.groups

    const currNumGroups = Object.keys(groups).length

    if (numGroups > currNumGroups) {
        for (let i = 0; i < numGroups + 1; i++) {
            if (!groups[i]) {
                groups[i] = {};
            }
        }
    } else if (numGroups < currNumGroups) {
        groups = rebalance(groups, numGroups)
    }

    const updatedNumPlayersPerGroup = Math.ceil(prevState.playerCount / numGroups);
    const tablesPerGroup2 = Math.ceil(prevState.numTables / numGroups);
    return {
        ...prevState,
        groups,
        tables: tablesPerGroup2,
        numGroups: action.data.numGroups,
        playersPerGroup: updatedNumPlayersPerGroup,
    }
}

function setPlayerPerGroup(prevState: State, action: Action<any>): State {
    const numPlayersPerGroup = action.data.playersPerGroup;
    const updatedNumGroups = Math.ceil(prevState.playerCount / numPlayersPerGroup);
    const tablesPerGroup = Math.ceil(prevState.numTables / updatedNumGroups);

    let groups2 = prevState.groups;
    const currNumGroups2 = Object.keys(groups2).length

    if (updatedNumGroups > currNumGroups2) {
        for (let i = 0; i < updatedNumGroups + 1; i++) {
            if (!groups2[i]) {
                groups2[i] = {};
            }
        }
    } else if (updatedNumGroups < currNumGroups2) {
        groups2 = rebalance(groups2, updatedNumGroups)
    }

    return {
        ...prevState,
        tables: tablesPerGroup,
        playersPerGroup: action.data.playersPerGroup,
        numGroups: updatedNumGroups,
        groups: groups2,
    }
}

function setTables(prevState: State, action: Action<any>): State {
    return {
        ...prevState,
        numTables: action.data.numTables,
        tables: Math.ceil(prevState.numGroups / action.data.numTables)
    }
}

function addPlayerToLeague(prevState: State, action: Action<any>): State {
    const player = action.data.player
    // do sorted
    const leaguePlayers = [...prevState.leaguePlayers, player];
    const availablePlayers = prevState.availablePlayers.filter(a => a.id !== action.data.player.id)
    const groupsCopy = prevState.groups;
    if (groupsCopy[player.group]) {
        groupsCopy[player.group][player.id] = player;
    } else {
        groupsCopy[player.group] = {}
        groupsCopy[player.group][player.id] = player;
    }

    return {
        ...prevState,
        leaguePlayers,
        availablePlayers,
        groups: groupsCopy,
        playerCount: leaguePlayers.length
    }
}

function removePlayerFromLeague(prevState: State, action: Action<any>): State {
    const player = action.data.player;
    const availablePlayers = [...prevState.availablePlayers, player];
    const leaguePlayers = prevState.leaguePlayers.filter(a => a.id !== player.id);
    const groups = prevState.groups;
    if (groups[player.group] && groups[player.group][player.id]) {
        delete groups[player.group][player.id];
    }

    return {
        ...prevState,
        availablePlayers,
        leaguePlayers,
        groups,
        playerCount: leaguePlayers.length,
    }
}

function changeGroup(prevState: State, action: Action<{ player: LeaguePlayer, groupNumber: number }>): State {
    if (isNaN(action.data.groupNumber)) {
        return prevState
    }

    const groupsCopy = prevState.groups;

    const newGroupNumber = action.data.groupNumber;
    const player = action.data.player;
    let leaguePlayers = prevState.leaguePlayers;
    const pIndex = leaguePlayers.findIndex(l => l.id === player.id);
    const oldGroupNumber = player.group;

    player.group = newGroupNumber;
    leaguePlayers[pIndex] = player;

    delete groupsCopy[oldGroupNumber][player.id]
    groupsCopy[newGroupNumber][player.id] = player;

    const updatedGroups = {
        [oldGroupNumber]: groupsCopy[oldGroupNumber],
        [newGroupNumber]: groupsCopy[newGroupNumber],
    }
    Object.assign(groupsCopy, updatedGroups)

    return {
        ...prevState,
        leaguePlayers,
        groups: groupsCopy
    }
}

function updateMatch(prevState: State, action: Action<{ lookupId: string, player: LeaguePlayer, status: matchStatus }>): State {
    // find match
    // set winner
    // player if win
    // opponent if lose
    // null if skip (todo: add flag)

    const ids = action.data.lookupId.split("_").map(id => parseInt(id));
    const matchIndex = prevState.matches.findIndex(m => {
        const matchIds = m.match.players.map(p => p.id);
        const hasPlayer0 = matchIds.indexOf(ids[0]) !== -1;
        const hasPlayer1 = matchIds.indexOf(ids[1]) !== -1;
        return hasPlayer0 && hasPlayer1
    })

    if (matchIndex === -1) {
        return prevState;
    }

    const match = prevState.matches[matchIndex];

    switch (action.data.status) {
        case "win":
            match.match.winner = leaguePlayerToPlayer(action.data.player);
            break;
        case "lose":
            const opponent = match.match.players.find(p => p.id !== action.data.player.id)
            if (opponent) {
                match.match.winner = opponent;
            }
            break;
        case "skip":
            match.match.winner = {id: 0, firstName: '', lastName: '', rating: 0}
            break;
        default:
            throw new Error()
    }


    const matchCopy = prevState.matches;
    matchCopy[matchIndex] = match;

    return {
        ...prevState,
        matches: matchCopy
    }
}