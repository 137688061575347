import React from 'react';
import {LeagueEvent} from "../domain/leagueevent/models";
import './ReviewEvent.css';
import {WorkPane, WorkPaneHeader} from "../component-library/WorkPane";
import {AppContainer} from "../domain/AppContainer";
import {useObservableWithInitialValue} from "../hooks/useEntityStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import {Player} from "../domain/player/models";

interface Props {
    container: AppContainer
    event: LeagueEvent
}


export function ReviewEvent({container, event}: Props) {
    const matches = useObservableWithInitialValue(container.leagueEvent.query.matches$, [])

    const refresh = () => {
        const active = container.leagueEvent.query.getActive()
        container.leagueEvent.service.clear()
        if (active) {
            container.leagueEvent.service.selectLeagueById(active.id)
        }
    }

    return <section className="league-events-body">
        <header className="league-events-body-header">
            <h3>{event.name} ({event.dateString})</h3>
        </header>
        <WorkPane>
            <WorkPaneHeader>Results</WorkPaneHeader>
            <div className="actions">
                <button className="transparent-border-button" onClick={() => refresh()}>
                    Refresh <FontAwesomeIcon icon={faSync}/>
                </button>
            </div>
            <table className="review-results-table">
                <thead>
                <tr>
                    <th>Group</th>
                    <th colSpan={2}>Players</th>
                </tr>
                </thead>
                <tbody>
                {matches?.map((m, i) => {
                    const winner = m.players.find(p => p.id === m.winnerId);
                    const showRating = m.match && m.match?.winnerRatingBefore !== 0

                    const ratingData = (p: Player) => <div><small>{p.id === winner?.id
                        ? `${m.match?.winnerRatingBefore} - ${m.match?.winnerRatingAfter}`
                        : `${m.match?.loserRatingBefore} - ${m.match?.loserRatingAfter}`}</small></div>

                    return <tr key={`${m.id}-${i}`}>
                        <td>{m.groupNumber}</td>
                        {m.players.map(p => <td key={p.id}
                                                className={`results-cell ${p.id === winner?.id && "result-cell-winner"}`}>
                            <div><small>{p.id === winner?.id ? "Won" : "Lost"}</small></div>
                            <div className="results-cell-name">{p.firstName} {p.lastName}</div>
                            {showRating && ratingData(p)}
                        </td>)}
                    </tr>
                })}
                </tbody>
            </table>
        </WorkPane>
    </section>
}