import {PlayerStore} from "./store";
import {PlayerService} from "./service";
import {PlayerQuery} from "./query";
import {LeagueContainer} from "../league";
import {PlayerGateway} from "./gateway";

export class PlayerContainer {
    readonly service: PlayerService;
    readonly query: PlayerQuery;
    private readonly store: PlayerStore;

    constructor(league: LeagueContainer) {
        this.store = new PlayerStore()
        this.query = new PlayerQuery(this.store);
        const gateway = new PlayerGateway(this.store)
        this.service = new PlayerService(this.store, this.query, gateway, league.query);
    }
}