import {UIStore} from "./store";
import {SlugType} from "../../components/Modal";
import {noop} from "../../util/etc";

export class UIService {
    constructor(private store: UIStore) {
    }

    activateModalSlug(slug: SlugType, onConfirm: () => void, onCancel?: () => void) {
        if (!onCancel) {
            onCancel = noop
        }
        this.store.update({modalActive: true, slug, onConfirm, onCancel})
    }

    activateModal(modalText: string, onConfirm: () => void, onCancel?: () => void) {
        if (!onCancel) {
            onCancel = noop
        }
        this.store.update({modalActive: true, slug: '', modalText, onConfirm, onCancel})
    }

    dismissModal() {
        this.store.update({
            modalActive: false,
            modalText: '',
            slug: '',
            onConfirm: noop,
            onCancel: noop,
        });
    }

    onCancel() {
        const cancelCb = this.store.getValue().onCancel;
        if (cancelCb) {
            cancelCb()
        }
        this.dismissModal();
    }

    onConfirm() {
        this.store.getValue().onConfirm()
        this.dismissModal();
    }
}