import {Permission} from "../../api/types";

//TODO: Move to user
export interface User {
    id: number
    source: string
    sourceId: string
    firstName: string
    lastName: string
    email: string
    createdAt: Date
    updatedAt: Date
}

export function emptyUser(): User {
    return {
        id: 0,
        source: '',
        sourceId: '',
        firstName: '',
        lastName: '',
        email: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    }
}

export interface AuthState {
    authenticated: boolean
    user: User
    permissions: Permission[]
    checked: boolean
    loading: boolean
}

export function initialState(): AuthState {
    return {
        authenticated: false,
        user: emptyUser(),
        permissions: [],
        checked: false,
        loading: false,
    }
}