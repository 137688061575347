import {PlayerContainer} from "./player";
import {LeagueContainer} from "./league";
import {MatchContainer} from "./match/container";
import {AuthContainer} from "./auth";
import {ID} from "@datorama/akita";
import {UIContainer} from "./ui";
import {LeagueEventContainer} from "./leagueevent/container";
import {UserContainer} from "./user";
import {MeContainer} from "./me";

export class AppContainer {
    readonly ui: UIContainer;
    readonly auth: AuthContainer;
    readonly league: LeagueContainer;
    readonly match: MatchContainer;
    readonly player: PlayerContainer;
    readonly leagueEvent: LeagueEventContainer
    readonly user: UserContainer;
    readonly me: MeContainer;

    constructor() {
        this.ui = new UIContainer()
        this.auth = new AuthContainer()
        this.me = new MeContainer()
        this.user = new UserContainer()
        this.league = new LeagueContainer(this.auth.service)
        this.player = new PlayerContainer(this.league)
        this.match = new MatchContainer(this.player)
        this.leagueEvent = new LeagueEventContainer(this.player, this.league)
    }

    init() {
        // this is happening twice
        if (this.auth.query.isAuthenticated) {
            this.league.service.loadAll();
        }
    }

    initLeague(leagueId: ID) {
        this.league.service.select(leagueId)
    }
}