import {UserStore} from "./store";
import {UserService} from "./service";
import {UserQuery} from "./query";

export class UserContainer {
    readonly service: UserService
    readonly query: UserQuery
    private readonly store: UserStore

    constructor() {
        this.store = new UserStore()
        this.query = new UserQuery(this.store)
        this.service = new UserService(this.store)
    }
}