import React, {useEffect, useState} from 'react';
import './LeagueManagement.css';
import {AppContainer} from "../domain/AppContainer";
import {PlayerTable} from "../components/PlayerTable";
import {PlayerPaneActions} from "../components/PlayerPaneActions";
import {Player} from "../domain/player/models";
import {Snackbar} from "../component-library/Snackbar";
import {useHistory, useParams} from "react-router-dom";

interface Props {
    container: AppContainer
}

interface Params {
    leagueId: string
}

export function LeagueManagement({container}: Props) {
    const history = useHistory()
    const {leagueId} = useParams<Params>()
    const [active, setActive] = useState<Player | undefined>()
    const [showPlayerForm, setShowPlayerForm] = useState<boolean>(true)
    const [showMatchBuilder, setShowMatchBuilder] = useState<boolean>(true)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(true)

    useEffect(() => {
        const s = container.player.query.selectActive().subscribe(setActive);
        return () => {
            s.unsubscribe();
        }
    }, [container.player.query])

    function createEvent() {
        container.leagueEvent.service.clear()
        container.leagueEvent.service.createLeagueEvent()
        history.push(`/league/${leagueId}/events`)
    }

    return (
        <div className="League">
            <main className="League-body">
                <section className="column player-table-column">
                    <PlayerTable container={container}/>
                </section>
                <section className="column player-pane-column">
                    {showSnackbar && (
                        <Snackbar
                            dismissable={true}
                            level={"question"}
                            showIcon={true}
                            onDismiss={() => {
                                setShowSnackbar(false)
                            }}>
                            Recording league results? <button className="link-button"
                                                              onClick={() => createEvent()}>Create an event</button>
                        </Snackbar>
                    )}
                    <PlayerPaneActions
                        container={container}
                        active={active}
                        showPlayerForm={showPlayerForm}
                        setShowPlayerForm={setShowPlayerForm}
                        showMatchBuilder={showMatchBuilder}
                        setShowMatchBuilder={setShowMatchBuilder}/>
                </section>
            </main>
        </div>
    );
}
