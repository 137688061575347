import {MatchStore} from "./store";
import {debounceTime, tap} from "rxjs/operators";
import {GetMatch, Match, MatchRequest, MatchResponse} from "./models";
import {ajax} from "rxjs/ajax";
import {applyTransaction, ID} from "@datorama/akita";
import {apiMatchToDomainMatch, convertGetMatchToMatch} from "./utils";
import {MatchQuery} from "./query";
import {Player} from "../player/models";
import {PlayerService} from "../player/service";
import {PlayerQuery} from "../player/query";
import {MatchGateway} from "./gateway";
import {Match as ApiMatch, ResponseWrapper} from "../../api/types";
import {idToNumber} from "../../util/types";

const MatchRoutes = {
    getForPlayer: (id: ID) => `/api/players/${id}/matches`
};

export class MatchService {
    constructor(private store: MatchStore, private query: MatchQuery, private gateway: MatchGateway, private playerService: PlayerService, private playerQuery: PlayerQuery) {
        playerQuery.selectActiveId()
            .pipe(debounceTime(200))
            .subscribe(val => {
                if (val) {
                    // this.getMatchesForPlayer(val)
                    this.listForPlayer(val)
                }
            })
        this.gateway.listForPlayer$.subscribe(v => this.onListForPlayer(v))
    }

    listForPlayer(playerId: ID) {
        this.store.reset()
        this.gateway.onListForPlayer$.next(playerId)
    }

    onListForPlayer(response: ResponseWrapper<{ matches: ApiMatch[] }>) {
        applyTransaction(() => {
            this.store.setLoading(false)
            this.store.add(response.data.matches.map(apiMatchToDomainMatch))
        })
    }

    // Old
    getMatchesForPlayer(id: ID) {
        this.store.reset()
        this.store.setLoading(true)
        ajax.getJSON<GetMatch[]>(MatchRoutes.getForPlayer(id))
            .subscribe(getMatches => {
                let matches: Match[] = []
                if (getMatches) {
                    matches = getMatches.map(convertGetMatchToMatch)
                }
                this.store.set(matches)
                this.store.setLoading(false);
            });
    }

    record(match: Match) {
        const request: MatchRequest = {
            leagueId: idToNumber(match.leagueId),
            players: {
                v1: match.players[0],
                v2: match.players[1],
            },
            winner: match.winner,
            // TODO: handle undefined
            loser: match.players.find(p => p.id !== match.winner.id)!,
            // TODO: record wins and losses
            wins: 0,
            losses: 0,
            date: match.date
        }

        let request$ = ajax
            .post(`/api/matches`, request, {'Content-Type': 'application/json'})
            .pipe(tap(() => this.store.setLoading(true)));

        request$.subscribe({
            next: response => {
                const result = response.response as MatchResponse
                this.playerService.setRating(result.WinnerId, result.WinnerRating)
                this.playerService.setRating(result.LoserId, result.LoserRating)
                this.store.setLoading(false);
                this.resetMatchBuilder();
            },
            error: err => console.error('error', err)
        });
    }

    addToMatch(player: Player) {
        const players = this.query.matchBuilderPlayers
        if (players.length < 2) {
            this.playerService.setIsInMatchBuilder(player, true);
            this.store.updateMatchBuilderPlayers([...players, player]);
        }
    }

    removeFromMatch(player: Player) {
        const players = this.query.matchBuilderPlayers
        this.playerService.setIsInMatchBuilder(player, false);
        if (players.length === 1) {
            this.store.updateMatchBuilderPlayers([]);
        } else if (players.length > 1) {
            const filtered = players.filter(p => p.id !== player.id);
            this.store.updateMatchBuilderPlayers(filtered);
        }
    }

    resetMatchBuilder() {
        const players = this.query.matchBuilderPlayers;
        players.forEach(p => this.removeFromMatch(p));
    }
}
