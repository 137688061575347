import React, {useEffect, useState} from 'react';
import {AppContainer} from "../domain/AppContainer";
import {Player} from "../domain/player/models";
import './MatchBuilder.css'
import {Match} from "../domain/match/models";
import {dateToDatestring, offsetDate} from "../domain/leagueevent/utils";

interface Props {
    container: AppContainer
    onDismiss: () => any
}

export function MatchBuilder({container, onDismiss}: Props) {
    const [matchWinner, setMatchWinner] = useState<Player>();
    const [matchDateString, setMatchDateString] = useState<string>(dateToDatestring(offsetDate(new Date())));
    const [matchBuilderPlayers, setMatchBuilderPlayers] = useState<Player[]>([]);

    useEffect(() => {
        const s = container.match.query.matchBuilderPlayers$.subscribe(setMatchBuilderPlayers)
        return () => {
            s.unsubscribe();
        }
    }, [container.match.query]);


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let leagueId = container.league.query.getActiveId();

        if (leagueId && matchBuilderPlayers.length === 2) {
            // TODO: separate streams that merge into an object builder
            // TODO: should not be type Match, that should be for Match response only

            const match: Match = {
                id: 0,
                leagueId: leagueId,
                players: [matchBuilderPlayers[0], matchBuilderPlayers[1]],
                winner: matchWinner!,
                wins: 2,
                losses: 0,
                date: new Date(matchDateString),
                winnerRatingBefore: 0,
                winnerRatingAfter: 0,
                loserRatingBefore: 0,
                loserRatingAfter: 0,
            };

            container.match.service.record(match);
        }
    }

    useEffect(() => {
        setMatchWinner(undefined)
    }, [matchBuilderPlayers])

    return (
        <section className="match-builder">
            <header className="match-builder-header">
                <h3>Build Match</h3>
                <button className="unfocus" onClick={() => {
                    container.match.service.resetMatchBuilder()
                    onDismiss()
                }}>&times;</button>
            </header>
            <form onSubmit={handleSubmit}>
                <div className="form-container">
                    <fieldset className="form-row">
                        <legend>Players</legend>
                        <ul>
                            {matchBuilderPlayers.map((p, i) => {
                                const selected = matchWinner && matchWinner.id === p.id;
                                return <li key={`${i}-${p.id}`}>
                                    <div className="form-field-group form-field-radio-group">
                                        <label
                                            className={`match-builder-players-label ${selected && 'selected'}`}
                                            htmlFor={`match-builder-players-${p.id}`}>
                                            <input
                                                name="match-builder-players"
                                                type="radio"
                                                id={`match-builder-players-${p.id}`}
                                                checked={selected}
                                                disabled={matchBuilderPlayers.length !== 2}
                                                onChange={() => setMatchWinner(p)}/>
                                            <div className="match-builder-person-info">
                                                <p className="match-builder-name">{p.firstName} {p.lastName}</p>
                                                <p className="match-builder-rating"><small>{p.rating}</small></p>
                                            </div>
                                            <p className="match-builder-remove">
                                                <button
                                                    className="remove"
                                                    onClick={() => {
                                                        container.match.service.removeFromMatch(p)
                                                    }}>
                                                    &times;
                                                </button>
                                            </p>
                                            {matchWinner !== undefined && (<div
                                                className="match-builder-won-loss">
                                                <small>{selected ? 'Won' : 'Lost'}</small></div>)}

                                        </label>

                                    </div>
                                </li>
                            })}
                            {matchBuilderPlayers.length < 2 && <li>
                                <div className="form-field-group form-field-radio-group form-field-group-empty">
                                    {matchBuilderPlayers.length === 1 ? 'Select another player' : 'Click the checkbox next to a player name to get started'}
                                </div>
                            </li>}
                        </ul>
                    </fieldset>

                    {matchBuilderPlayers.length === 2 && (<>
                        <FinalScoreFields enabled={false}/>
                        <div className="form-row form-field-group match-date">
                            <label htmlFor="match-date">Match Date</label>
                            <input
                                type="date"
                                value={matchDateString}
                                onChange={e => setMatchDateString(e.target.value)}/>
                        </div>
                        <input
                            className='match-builder-submit'
                            type="submit"
                            disabled={matchBuilderPlayers.length < 2}/>
                    </>)}

                </div>
            </form>
        </section>
    );
}

function FinalScoreFields(props: { enabled: boolean }) {
    if (props.enabled) {
        return <div className="form-row form-field-group match-score">
            <label htmlFor="match-score" className="match-score-label">Final Score</label>
            <div id="match-score" className="match-score-group">
                <div className="match-score-input">
                    <label htmlFor="match-score-wins">Wins</label>
                    <input type="number" className="match-score-wins"/>
                </div>
                <div className="match-score-input">
                    <label htmlFor="match-score-losses">Losses</label>
                    <input type="number" className="match-score-losses"/>
                </div>
            </div>
        </div>
    }

    return null

}