import {UserStore} from "./store";
import {interval, Subject} from "rxjs";
import {debounce, switchMap, tap} from "rxjs/operators";
import {list} from "../../api/gateway";
import {ListUsersResponse} from "../../api/types";
import {ListUsers} from "../../api/endpoints";
import {applyTransaction} from "@datorama/akita";

export class UserService {
    readonly onSearch$ = new Subject<string>()
    readonly list$ = this.onSearch$.pipe(
        debounce(() => interval(500)),
        tap(() => this.store.setLoading(true)),
        switchMap((q) => list<ListUsersResponse>(ListUsers(q)))
    ).subscribe({
        next: response => {
            applyTransaction(() => {
                this.store.reset()
                this.store.add(response.data)
                this.store.setLoading(false)
            })
        },
        error: err => {
            console.error('list$ - err', err)
            this.store.setLoading(false)
        },
    })

    constructor(private store: UserStore) {
    }

    search(value: string) {
        this.onSearch$.next(value)
    }
}