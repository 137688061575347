import {MeState} from "./models";
import {Query} from "@datorama/akita";
import {MeStore} from "./store";

export class MeQuery extends Query<MeState> {
    readonly watchList$ = this.select(s => s.watchList)
    readonly matchFeed$ = this.select(s => s.matchFeed)

    constructor(protected store: MeStore) {
        super(store);
    }
}
