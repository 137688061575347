import {ID, Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {isPlayerUI, Player, PlayerState, PlayerUI} from "./models";
import {filterNullish} from "../../util/operators";
import {filter, map, switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {PlayerStore} from "./store";

@QueryConfig({sortBy: 'rating', sortByOrder: Order.DESC})
export class PlayerQuery extends QueryEntity<PlayerState> {
    readonly players$ = this.selectAll()
    readonly activeUI$ = this.selectActive().pipe(filterNullish(), switchMap((a: Player) => this.ui.selectEntity(a.id)))
    public tempRating: number = 0
    readonly tempRating$: Observable<number> = this.activeUI$.pipe(
        filterNullish(),
        filter(x => isPlayerUI(x)),
        map(x => (x as PlayerUI).tempRating)
    );
    readonly ready$: Observable<boolean> = this.select(state => state.ui.ready)

    constructor(protected store: PlayerStore) {
        super(store);
        this.createUIQuery();
        this.tempRating$.subscribe(x => this.tempRating = x)
    }

    getPlayerUI(id: ID): PlayerUI | unknown {
        let entity = this.ui.getEntity(id);
        return entity;
    }
}