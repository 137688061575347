import React, {useEffect, useState} from 'react';
import './Modal.css'
import {AppContainer} from "../domain/AppContainer";
import {ResetRatingModal} from "./ResetRatingModal";

interface Props {
    container: AppContainer
}

export const RESET_RATING_SLUG = 'reset-rating'
export type SlugType = 'reset-rating' | ''

export function Modal({container}: Props) {
    const [slug, setSlug] = useState<SlugType>('');

    useEffect(() => {
        const s = container.ui.query.slug$.subscribe(setSlug)
        return () => s.unsubscribe();
    }, [container.ui.query]);

    function close() {
        container.ui.service.dismissModal()
    }

    function getSlugContent(slug: SlugType) {
        switch (slug) {
            case RESET_RATING_SLUG:
                return <ResetRatingModal container={container}/>
            default:
                return null
        }
    }

    return <>
        <aside className={`modal ${slug}`}>
            <header>
                <button className="modal-dismiss" onClick={() => close()}>&times;</button>
            </header>
            <main className="modal-body">
                {slug === '' ? <p>{container.ui.query.getValue().modalText}</p> : getSlugContent(slug)}
            </main>
            <footer className="modal-footer">
                <button
                    className="modal-cancel"
                    onClick={() => container.ui.service.onCancel()}>
                    Cancel
                </button>
                <button
                    className="modal-confirm"
                    onClick={() => container.ui.service.onConfirm()}>
                    Confirm
                </button>
            </footer>
        </aside>
        <div className="modal-overlay" onClick={() => close()}/>
    </>
}