import {LeagueEventQuery} from "./query";
import {interval, Observable, pipe, Subject} from "rxjs";
import {debounce, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {
    AddPlayerRequest,
    BulkAddLeagueEventMatchJson,
    BulkAddLeagueEventMatchResponse,
    ChangeGroupRequest,
    GetMatchesResponse,
    RemoveParticipantResponse,
    UpdateLeagueEventMatchRequest,
    UpdateLeagueEventRequest
} from "../../api/types";
import {create, list, remove, update} from "../../api/gateway";
import {
    AddParticipant,
    BulkAddLeagueEventMatch,
    GetParticipants,
    LeagueEventMatchesResource,
    LeagueEventMatchResource,
    LeagueEventResultsResource,
    RemoveParticipant,
    UpdateLeagueEvent,
    UpdateParticipant
} from "../../api/endpoints";
import {filterNullish} from "../../util/operators";
import {GroupPlayer} from "./models";
import {ID} from "@datorama/akita";
import {generateMatchupsFromParticipants} from "./utils";

type ChangeGroupData = { playerId: ID, groupNumber: number }

export class LeagueEventGateway {
    readonly update$ = new Subject<any>()
    readonly select$ = new Subject<any>()
    readonly addParticipant = new Subject<ID>();
    readonly removeParticipant = new Subject<ID>();
    readonly addMatches = new Subject<any>();
    readonly updateMatchSubject = new Subject<[ID, ID]>();
    readonly results$ = new Subject<any>();

    readonly changeGroup = new Subject<ChangeGroupData>();
    readonly put$ = this.update$.pipe(
        debounce(() => interval(500)),
        withLatestFrom(this.query.selectActive()),
        map(([, active]) => active),
        filterNullish(),
        switchMap(active => {
            const request: UpdateLeagueEventRequest = {
                name: active.name,
                date: new Date(active.dateString),
                status: active.status,
                groupCount: active.groupCount,
                tables: active.tables
            }

            return update(UpdateLeagueEvent(active.id), request);
        })
    )
    readonly addMatches$ = this.addMatches.pipe(
        debounce(() => interval(500)),
        withLatestFrom(this.query.selectActive()),
        map(([, active]) => active),
        filter(active => active != null),
        switchMap(active => {
            const matches = generateMatchupsFromParticipants(active!.participants)
            return create<BulkAddLeagueEventMatchJson, BulkAddLeagueEventMatchResponse>(BulkAddLeagueEventMatch(active!.id), {matches})
        })
    )

    readonly getParticipants$ = this.select$.pipe(
        debounce(() => interval(100)),
        withLatestFrom(this.query.selectActive()),
        map(([, active]) => active),
        filterNullish(),
        switchMap(active => list<GroupPlayer[]>(GetParticipants(active.id)))
    )

    readonly addParticipant$: Observable<GroupPlayer> = this.addParticipant.pipe(
        debounce(() => interval(100)),
        filterNullish(),
        withLatestFrom(this.query.selectActive()),
        filter(([, active]) => active != null),
        switchMap(([playerId, active]) => create<AddPlayerRequest, GroupPlayer>(AddParticipant(active!.id), {playerId}))
    )

    readonly removeParticipant$: Observable<RemoveParticipantResponse> = this.removeParticipant.pipe(
        debounce(() => interval(100)),
        filterNullish(),
        withLatestFrom(this.query.selectActive()),
        filter(([, active]) => active != null),
        switchMap(([playerId, active]) => remove<RemoveParticipantResponse>(RemoveParticipant(active!.id, playerId)))
    )
    readonly changeGroup$ = this.changeGroup.pipe(
        debounceAndGetActive(this.query.selectActive()),
        switchMap(([input, active]) => update<ChangeGroupRequest>(UpdateParticipant(active!.id, (input as ChangeGroupData).playerId), {groupNumber: (input as ChangeGroupData).groupNumber}))
    )

    readonly getMatches$ = this.select$.pipe(
        debounce(() => interval(100)),
        withLatestFrom(this.query.selectActive()),
        map(([, active]) => active),
        filterNullish(),
        switchMap(active => {
            if (active.status === 'done') {
                return list<GetMatchesResponse[]>(LeagueEventResultsResource(active.id))
            }

            return list<GetMatchesResponse[]>(LeagueEventMatchesResource(active.id))
        })
    )

    readonly updateMatch$ = this.updateMatchSubject.pipe(
        debounce(() => interval(100)),
        filterNullish(),
        withLatestFrom(this.query.selectActive()),
        filter(([, active]) => active != null),
        switchMap(([input, active]) => update<UpdateLeagueEventMatchRequest>(LeagueEventMatchResource(active!.id, input[0]), {winnerId: input[1]}))
    )

    readonly getResults$ = this.results$.pipe(
        debounce(() => interval(100)),
        withLatestFrom(this.query.selectActive()),
        map(([, active]) => active),
        filterNullish(),
        switchMap(active => list<GetMatchesResponse[]>(LeagueEventResultsResource(active.id))),
        tap((results) => {
            results[0].match.winnerRatingBefore === 0 && this.results$.next(null)
        })
    )

    constructor(private query: LeagueEventQuery) {
    }
}

function debounceAndGetActive<T>(active$: Observable<T>) {
    return pipe(
        debounce(() => interval(100)),
        filterNullish(),
        withLatestFrom(active$),
        filter(([, active]) => active != null),
    )
}
