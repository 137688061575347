import {League} from "../domain/league/models";
import {AppContainer} from "../domain/AppContainer";
import {useHistory} from "react-router-dom";
import {WorkPane, WorkPaneHeader} from "../component-library/WorkPane";
import {Button} from "../component-library/Button";
import React from "react";

interface LeagueSensitiveSettingsProps {
    active?: League
    container: AppContainer
}

export function LeagueSensitiveSettings({active, container}: LeagueSensitiveSettingsProps) {
    const history = useHistory()

    function archive() {
        if (active) {
            container.league.service.destroy(active.id)
            history.push('/')
        }
    }

    return <WorkPane>
        <WorkPaneHeader>Sensitive</WorkPaneHeader>
        <Button onClick={() => archive()}>
            Archive
        </Button>
    </WorkPane>
}