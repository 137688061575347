import React, {useEffect} from "react";
import {NavLink, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import {useObservable} from "../hooks/useEntityStore";
import {PageWrapper} from "../component-library/PageWrapper";
import {LeagueTitle} from "../component-library/LeagueTitle";
import {LeagueEvents} from "./LeagueEvents";
import {LeagueSettings} from "./LeagueSettings";
import {LeagueManagement} from "./LeagueManagement";
import {AppContainer} from "../domain/AppContainer";
import {LeagueDashboard} from "./LeagueDashboard";
import {useAuth} from "../hooks/useAuth";
import './LeagueRouter.css';
import {User} from "../domain/auth/models";
import {ScrollToTopOnMount} from "../component-library/ScrollToTop";

interface Props {
    container: AppContainer
    user?: User
}

interface Params {
    leagueId: string
}

export function LeagueRouter({container}: Props) {
    let routeMatch = useRouteMatch()
    let {leagueId} = useParams<Params>();
    const league = useObservable(container.league.query.active$)
    const canEdit = useAuth(leagueId, container)

    useEffect(() => {
        container.initLeague(leagueId)
    }, [leagueId, container])

    return <PageWrapper container={container}>
        <ScrollToTopOnMount/>
        <header className="league-router-header">
            <LeagueTitle leagueId={leagueId} name={league?.name}/>
        </header>
        {canEdit && <nav className="league-router-nav">
            <NavLink
                className="league-router-nav-item"
                activeClassName="league-router-nav-item-active"
                exact={true}
                to={`/league/${leagueId}`}>
                Dashboard
            </NavLink>
            <NavLink
                className="league-router-nav-item"
                activeClassName="league-router-nav-item-active"
                to={`/league/${leagueId}/manage`}>
                Manage
            </NavLink>
            <NavLink
                className="league-router-nav-item"
                activeClassName="league-router-nav-item-active"
                to={`/league/${leagueId}/events`}>
                Events
            </NavLink>
            <NavLink
                className="league-router-nav-item"
                activeClassName="league-router-nav-item-active"
                to={`/league/${leagueId}/settings`}>
                Settings
            </NavLink>
        </nav>}
        <Switch>
            <Route path={`${routeMatch.path}/events`}>
                {canEdit && <LeagueEvents container={container}/>}
            </Route>
            <Route path={`${routeMatch.path}/settings`}>
                {canEdit && <LeagueSettings container={container}/>}
            </Route>
            <Route path={`${routeMatch.path}/manage`}>
                {canEdit && <LeagueManagement container={container}/>}
            </Route>
            <Route exact path={`${routeMatch.path}`}>
                <LeagueDashboard container={container}/>
            </Route>
        </Switch>
    </PageWrapper>
}
