import React, {PropsWithChildren, useEffect, useState} from 'react'
import './Snackbar.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons/faQuestionCircle";

interface Props {
    dismissable?: boolean
    level: 'info' | 'warn' | 'alert' | 'question'
    showIcon?: boolean
    onDismiss?: () => any
}

export function Snackbar({dismissable, children, level, showIcon, onDismiss}: PropsWithChildren<Props>) {
    const [icon, setIcon] = useState<IconDefinition>(faInfoCircle)
    useEffect(() => {
        if (showIcon) {
            switch (level) {
                case "question":
                    setIcon(faQuestionCircle)
                    break;
                case "alert":
                    setIcon(faExclamationCircle)
                    break;
                case "info":
                default:
                    setIcon(faInfoCircle)
            }
        }
    }, [showIcon, level])

    return <div className={`snackbar snackbar-${level}`}>
        {showIcon && <FontAwesomeIcon icon={icon} className="snackbar-icon"/>}
        <div className="snackbar-contents">
            {children}
        </div>
        {dismissable && (
            <button className="snackbar-dismiss" onClick={() => onDismiss && onDismiss()}>
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        )}
    </div>
}