import React, {useEffect, useState} from 'react';
import './CreateLeagueEvent.css';
import {GroupPlayer, LeagueEvent} from "../domain/leagueevent/models";
import {AppContainer} from "../domain/AppContainer";
import {FieldsetInput} from "../component-library/Fieldsets";
import {useFuseFilter} from "../hooks/useFuseFilter";
import {useEntityStore, useObservable} from "../hooks/useEntityStore";
import {WorkPane} from "../component-library/WorkPane";
import {ClickablePlayerList} from "../component-library/ClickablePlayerList";
import {GroupBlock} from "./GroupSetup";
import {countToArray} from "../util/etc";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Player, PlayerState} from "../domain/player/models";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

interface Props {
    container: AppContainer
    event: LeagueEvent
}

export function CreateLeagueEvent({container, event}: Props) {
    const players = useEntityStore<PlayerState>(container.leagueEvent.query.availablePlayers$);
    const [filteredPlayers, playerCriteria, setPlayerCriteria] = useFuseFilter<Player>(players, {keys: ["firstName", "lastName"]});
    const [groupNumbers, setGroupNumbers] = useState<number[]>([0]);
    const groups = useObservable<{ [groupNumber: number]: GroupPlayer[] }>(container.leagueEvent.query.groups$);
    const [allowNext, setAllowNext] = useState<boolean>(false)

    useEffect(() => {
        setGroupNumbers(countToArray(event.groupCount + 1));
    }, [event.groupCount])

    useEffect(() => {
        const groupNumbers = groups ? Object.keys(groups) : []
        if (groupNumbers.length > 0 && groupNumbers[0] !== '0') {
            setAllowNext(true)
        } else {
            setAllowNext(false)
        }
    }, [groups])

    return <>
        <section className="league-events-body create-league-event">

            <main className="league-events-body-main">
                <WorkPane sticky={true} className="event-info">
                    <h3>Round Robin Event</h3>
                    <main className="event-info-body">
                        <div className="event-info-row">
                            <FieldsetInput
                                label={"Name"}
                                id={"event-name"}
                                value={event.name}
                                placeholder={"Example: Friday League"}
                                onChange={(v) => {
                                    container.leagueEvent.service.updateName(v)
                                }}/>

                            <FieldsetInput
                                type={"date"}
                                label={"Date"}
                                id={"event-date"}
                                value={event.dateString}
                                onChange={(v) => {
                                    container.leagueEvent.service.updateDateString(v)
                                }}/>
                        </div>

                        <input
                            type="submit"
                            className="button"
                            disabled={!allowNext}
                            value={allowNext ? `Record Results` : 'Create a group to proceed'}
                            onClick={() => {
                                container.leagueEvent.service.setStatus("recording");
                            }}/>
                    </main>
                </WorkPane>

                <WorkPane>
                    <h4>Step 1. Add Participating Players</h4>
                    <p>Click the names of the players playing in this league event</p>
                    <div className="player-filter">
                        <FieldsetInput
                            label={"Filter"}
                            id={"filter-players"}
                            placeholder={"e.g., Player Name"}
                            value={playerCriteria}
                            onChange={(v) => setPlayerCriteria(v)}/>
                    </div>
                    <ClickablePlayerList
                        onClick={(p) => container.leagueEvent.service.addPlayerToEvent(p)}
                        players={filteredPlayers}/>
                </WorkPane>

                <WorkPane>
                    <h4>Step 2. Assign Players to Groups</h4>

                    <Unassigned
                        allowNext={allowNext}
                        onRemove={() => container.leagueEvent.service.setGroups(event.groupCount - 1)}
                        groupNumbers={groupNumbers}
                        groupNumber={0}
                        container={container}
                        groups={groups || {}}/>

                    {groupNumbers && groupNumbers.map((n, i) =>
                        <GroupBlock
                            onRemove={() => container.leagueEvent.service.setGroups(event.groupCount - 1)}
                            key={i}
                            container={container}
                            groupNumbers={groupNumbers}
                            groups={groups || {}}
                            groupNumber={n}/>
                    )}

                    <AddGroupBlock onClick={() => container.leagueEvent.service.setGroups(event.groupCount + 1)}/>
                </WorkPane>

                <WorkPane>
                    <h4>Step 3. Click Record Results when ready</h4>
                    <input
                        type="submit"
                        className="button"
                        disabled={!allowNext}
                        value={allowNext ? `Record Results` : 'Create a group to proceed'}
                        onClick={() => {
                            container.leagueEvent.service.setStatus("recording");
                        }}/>
                </WorkPane>
            </main>
        </section>
    </>
}

interface AddGroupBlockProps {
    onClick: () => any
}

function AddGroupBlock(props: AddGroupBlockProps) {
    return <div className="work-pane event-group add-group" onClick={() => props.onClick()}>
        <div><FontAwesomeIcon icon={faPlus}/> Add Group</div>
    </div>
}

interface UnassignedProps {
    onRemove: () => any
    groupNumbers: number[]
    groupNumber: number
    container: AppContainer
    groups: { [groupNumber: number]: GroupPlayer[] }
    allowNext: boolean
}

function Unassigned(props: UnassignedProps) {
    const groupPlayers = props.groups[props.groupNumber] || []

    let isUnassigned = false;
    if (props.groupNumber === 0) {
        isUnassigned = true;
    }

    if (!isUnassigned) {
        return null;
    }

    return <div className="work-pane event-group unassigned" key={props.groupNumber}>
        <header className="event-group-header">
            <h4 className="event-group-header-unassigned">Unassigned</h4>
        </header>

        <section className="event-group-players">
            {(groupPlayers.length === 0 && !props.allowNext)
                ? <p>Add participants in step 1</p>
                : <p>All set!</p>}
            {groupPlayers.length > 0 && (
                <p>Move a player into a group by clicking the drop-down to the right of each player's name</p>
            )}

            {groupPlayers.map((gp, i) => <div key={i} className="event-group-players-row">
                <div className="event-group-players-cell">{gp.player.firstName} {gp.player.lastName}</div>
                <div className="event-group-players-cell">{gp.player.rating}</div>
                <div className="event-group-selector event-group-players-cell">
                    <select
                        name="group-selector"
                        id={`group-selector-${gp.playerId}-${props.groupNumber}`}
                        value={props.groupNumber}
                        onChange={(e) => {
                            props.container.leagueEvent.service.changeGroup(gp, parseInt(e.target.value))
                        }}>
                        {props.groupNumbers.map((g, i) => <option key={i} value={g}>{g === 0 ? 'None' : g}</option>)}
                        <option value={props.groupNumbers.length}>{props.groupNumbers.length}</option>
                    </select>
                </div>
                <div className="event-group-players-cell">
                    <button className="transparent-button" onClick={() => {
                        props.container.leagueEvent.service.removePlayerFromEvent(gp.player)
                    }}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
            </div>)}
        </section>
    </div>
}
