import React, {ReactNode} from 'react';
import './WorkPane.css';

interface WorkPaneProps {
    children: ReactNode
    className?: string
    sticky?: boolean
}

export function WorkPane(props: WorkPaneProps) {
    return <div className={`work-pane ${props.sticky && 'sticky'} ${props.className}`}>
        {props.children}
    </div>
}

interface WorkPaneHeaderProps {
    children: ReactNode
}

export function WorkPaneHeader(props: WorkPaneHeaderProps) {
    return <header className="work-pane-header">
        <h4>{props.children}</h4>
    </header>
}